.user-menu {
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  padding: 30px;
  padding-top: 36px;
  padding-left: 26px;
  .menu-item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  .menu-link {
    &:hover:not(.current) {
      opacity: 0.6;
    }
    &.current {
      font-weight: 500;
    }
  }
  button.menu-link {
      background: transparent;
      border: 0;
      color: #383837;
      font-family: "IBM Plex Sans";
      font-size: 14px;
      padding: 6px 0;
  }
  .logout-link {
    color: #9c9b9b;
  }
  .logout-item {
    padding-top: 10px;
  }
  .count {
    display: inline-block;
    padding-left: 7px;
    color: $c-blue;
  }
}

.user-menu + .form-validate-message {
    margin-top: 40px;
}

.area-content {
  & > * {
    &:not(:last-child) {
      margin-bottom: 60px;
      @media (max-width: $max_sm) {
        margin-bottom: 40px;
      }
    }
  }
  .page-title {
    margin-bottom: 32px;
    @media (max-width: $max_sm) {
      margin-bottom: 25px;
    }
  }
  .page-subtitle {
    margin-bottom: 30px;
    padding-top: 8px;
    @media (max-width: $max_sm) {
      margin-bottom: 25px;
    }
  }
  .btn-show-more {
    margin-top: 15px;
  }
}

.form.edit-user-personal-dates {
  .btn-row {
    padding-top: 32px;
  }
  @media (max-width: $max_xs) {
    .btn[type="submit"] {
      width: 100%;
    }
  }
}

.summary-list {
  @media (max-width: $max_xs) {
    margin-top: -20px;
  }
  .summary-card {
    @media (min-width: $min_xs) {
      border-radius: 3px;
      border: 1px solid #e1e1e1;
      padding: 30px;
    }
    @media (max-width: $max_xs) {
      &:not(:last-child) {
        border-bottom: 1px solid #e1e1e1;
      }
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
      padding: 20px $sm_col_width;
    }
    position: relative;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    .left {
      display: flex;
      align-items: center;
    }
    .views {
      margin-right: 20px;
    }
    @media (max-width: $max_xs) {
      flex-wrap: wrap;
      padding-top: 10px;
      .right {
        order: 1;
        width: 100%;
        padding-bottom: 15px;
      }
      .left {
        order: 2;
      }
    }
  }

  .title {
    margin-bottom: 15px;
  }

  .vac-controls-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    .controls,
    .controls a {
      color: $c-dgrey;
    }
    .controls a:hover {
      opacity: 0.6;
    }
    .cont-divider {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (max-width: $max_xs) {
      display: block;
      padding-top: 15px;
      .btn {
        width: 100%;
      }
      .controls {
        padding-top: 15px;
      }
      .tag-btn {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: left;
        transform: translateX(-$sm_col_width);
        width: calc(100% + #{$sm_col_width} * 2);
        padding-left: $sm_col_width;
      }

      &.has-date {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 5px;
        .date {
          display: block;
          margin-bottom: 15px;
        }
      }
    }
  }

  .delete-summary {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 5;
    @media (max-width: $max_xs) {
      top: 20px;
      right: 15px;
    }
  }
}

.user-reviews-container {
  .tabs-wrap {
    padding-top: 25px;
  }
}

.add-vacancy-form {
  &:not(:last-child) {
    margin-bottom: 35px !important;
  }
  .form-col {
    padding-bottom: 12px;
  }
  .btn[type="submit"] {
    @media (max-width: $max_xs) {
      width: 100%;
    }
  }

  .choice-list .choice-item:not(:last-child) {
    margin-bottom: 15px;
  }

  .btn[type="submit"] {
    min-width: 255px;
  }
}

.pay-vacancy-form {
  padding-top: 35px;
  border-top: 1px solid #e0e1e4;
  margin-bottom: 30px;
  .title {
    margin-bottom: 30px;
  }
  @media (min-width: $min_sm) {
    .content {
      background-color: #fff;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      padding: 30px;
    }
  }

  .choise-opt {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .total-row {
    padding-top: 10px;
  }
}

.user-tarifs-list {
  .notar-user-card,
  .user-tarif-card {
    padding: 25px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    @media (max-width: $max_sm) {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      padding-bottom: 20px;
    }
  }

  .notar-user-card {
    .btn-row {
      padding-top: 25px;
    }
    .btn {
      padding-left: 35px;
      padding-right: 25px;
    }
    .content {
      max-width: 400px;
      margin: 0 auto;
    }
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width: $max_xs) {
      border: none;
      padding: 0;
      .content {
        text-align: left;
        max-width: 100%;
      }
      .btn {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  @media (min-width: $min_sm) {
    .user-tarif-card {
      display: flex;
      align-items: stretch;
    }
    .state-col {
      width: 40%;
      min-width: 300px;
      flex-shrink: 0;
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      padding-right: 23px;
    }
  }

  .title-wrap {
    margin: auto;
    @media (max-width: $max_xs) {
      text-align: left;
    }
  }
  .btn-wrap {
    margin-top: auto;
    .btn {
      width: 100%;
    }
  }
  .tar-title {
    margin-bottom: 15px;
  }
  .tar-meta {
    margin: 0 0 20px;
  }
  .content-col {
    width: 100%;
    @media (min-width: $min_sm) {
      padding-left: 31px;
      border-left: 1px solid #e5e5e5;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @media (max-width: $max_sm) {
      padding-top: 18px;
      padding-bottom: 23px;
    }
  }

  .tarif-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .state-desc {
    display: block;
    margin-bottom: 10px;
  }
}

.user-base-access {
  .title {
    margin-bottom: 30px;
  }
  .access-state {
    margin-bottom: 15px;
  }
  .btn-row {
    padding-top: 30px;
  }
  @media (min-width: $min_xs) {
    .access-card {
      background-color: #fff;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      padding: 70px 20px 60px 20px;
    }
  }
  @media (max-width: $max_xs) {
    .btn {
      width: 100%;
    }
    .access-card {
      text-align: left;
    }
    .title {
      margin-bottom: 25px;
    }
  }

  &.access-locked {
    .heading-title {
      margin-bottom: 5px;
    }
    .heading {
      margin-bottom: 25px;
    }
    .form {
      .btn {
        width: 100%;
      }
    }
    .btn-row {
      padding-top: 2px;
    }

    @media (min-width: $min_xs) {
      .access-card {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      .form {
        max-width: 255px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (max-width: $max_xs) {
      .heading {
        text-align: left;
        margin-bottom: 10px;
      }
      .heading-title {
        margin-bottom: 27px;
      }
      .heading-subtitle {
        font-size: 14px;
        font-weight: normal;
      }
      .btn-row {
        padding-top: 0;
      }
    }
  }
}

.area-content .company-vacancies-block {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media (max-width: $max_md) {
    margin-bottom: 0 !important;
  }

  .block-title {
    margin-bottom: 30px;
  }
}

.area-content.company-vacancies {
  @media (min-width: $min_md) {
    .tab:not(.active) {
      display: block !important;
    }
  }
  @media (max-width: $max_sm) {
    .tab-link {
      padding-bottom: 18px;
    }
  }
  .tabs-over-container {
    margin-bottom: 15px;
    .bottom-arrow {
      height: 3px;
    }
  }
}

.area-content.user-summaries-list {
  .page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      font-size: 14px;
      min-width: 190px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 13.5px;
      padding-bottom: 13.5px;
    }
  }
}

.area-content.company-pay-services {
  @media (max-width: $max_sm) {
    .page-title {
      margin-bottom: 25px;
    }
    .page-subtitle {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
      padding-top: 25px;
      margin-bottom: 30px;
      border-top: 1px solid rgba(140, 153, 176, 0.3);
      padding-left: $sm_col_width;
      padding-right: $sm_col_width;
    }
  }
}

.tabs-over-container {
  overflow: auto;
  @media (max-width: $max_sm) {
    border-bottom: 1px solid rgba(140, 153, 176, 0.3);
  }
  .tab-link {
    white-space: nowrap;
    padding-bottom: 13px;
  }
  .tab-link-item {
    padding-left: 12.5px;
    padding-right: 12.5px;
  }
  .tabs-links-wrap {
    margin-bottom: 0px;
    margin-left: -12.5px;
    margin-right: -12.5px;
  }
  .bottom-arrow {
    height: 3px;
  }
}

.prof-card {
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  padding: 30px;
  .head-row {
    display: flex;
    position: relative;
  }
  .avatar {
    width: 55px;
    height: 55px;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name-col {
    width: 100%;
    padding-left: 20px;
    @media (max-width: $max_xs) {
      padding-left: 15px;
    }
  }
  .name {
    margin-bottom: 15px;
    padding-right: 25px;
  }
  .toggle-collapse .ico {
    position: static;
  }
  .toggle-collapse {
    position: absolute;
    top: 0;
    right: 0;
    @media (max-width: $max_xs) {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .meta {
    display: flex;
    align-items: center;
    padding-top: 15px;
    @media (max-width: $max_xs) {
      flex-wrap: wrap;
    }
  }
  .date {
    margin-left: auto;
  }
  .meta-divider {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    @media (max-width: $max_xs) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .body-row {
    padding-top: 25px;
  }
}

.vac-feedback-block {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  .prof-card:not(:last-child) {
    @media (min-width: $min_sm) {
      margin-bottom: 15px;
    }
  }

  .prof-card {
    @media (max-width: $max_sm) {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding: 20px;
    }
  }

  .vac-card {
    margin-bottom: 25px;
  }

  .tabs-wrap {
    @media (max-width: $max_sm) {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
    }
  }

  .tab {
    @media (min-width: $min_sm) {
      padding-top: 25px;
    }
  }

  .info-row {
    padding-top: 25px;
    @media (min-width: $min_xs) {
      display: flex;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .info-title {
    margin-bottom: 15px;
  }
  .info-col {
    @media (min-width: $min_xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (max-width: $max_xs) {
      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }
  }

  .action-row {
    padding-top: 30px;
    display: flex;
    align-items: stretch;
    .btn {
      min-width: 165px;
      padding-left: 15px;
      padding-right: 15px;
      margin-right: 15px;
    }
    @media (max-width: $max_xs) {
      justify-content: space-between;
      .btn {
        min-width: auto;
        margin: 0;
        width: calc(50% - 5px);
      }
    }
  }
}

.user-add-summary {
  .form .collapse {
    padding-top: 10px;
  }
  .page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.notice {
    background-color: #ed4947;
    color: #fff;
    margin-top: 40px;
    border-radius: 3px;
    padding: 30px;
    &-text {
        margin-bottom: 20px;
    }
    .btn {
        padding: 10px 20px;
    }
}

.user-top-menu {
    .notice {
        @media (max-width: $max_md) {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 30px;
            h3 {
                margin-bottom: 0;
                margin-right: 30px;
            }
            &-text {
                display: none;
            }
        }
        @media (max-width: $max_xs) {
            align-items: stretch;
            flex-direction: column;
            h3 {
                margin-bottom: 10px;
                margin-right: 0;
                text-align: center;
            }
            .btn {
                width: 100%;
            }
        }
    }
    .form-validate-message {
        @media (max-width: $max_md) {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0;
            margin-bottom: 0;
            text-align: center;
        }
    }
}
