/*==============================================================
*												SECTION HOT VACANCIES
=============================================================== */
.section-vacancies_hot {
  .section-title {
    margin-bottom: 45px;
    @media (max-width: $max_sm) {
      margin-bottom: 27px;
    }
  }
  .vac-row {
    margin: -30px -15px;
    margin-top: 0;
    justify-content: center;
    @media (max-width: $max_xs) {
      margin-bottom: -15px;
    }
  }
  .vac-col {
    width: calc(100% / 3);
    padding: 30px 15px;
    padding-top: 0;
    @media (max-width: $max_md) {
      width: 50%;
    }
    @media (max-width: $max_xs) {
      padding-bottom: 15px;
    }
    @media (max-width: $max_xs) {
      width: 100%;
    }
  }
  .vac-item {
    display: block;
    padding: 25px;
    @media (max-width: $max_sm) {
      padding: 20px;
    }
  }
  .card-content {
    position: relative;
    z-index: 2;
    pointer-events: none;
  }

  .title {
    margin-bottom: 15px;
    @media (max-width: $max_sm) {
      margin-bottom: 8px;
    }
  }
  .amount {
    margin-bottom: 18px;
    @media (max-width: $max_sm) {
      margin-bottom: 12px;
    }
  }
  .btn-row {
    padding-top: 45px;
    @media (max-width: $max_sm) {
      padding-top: 30px;
    }
  }
}
