/*==============================================================
*												SECTION TARIFS
=============================================================== */
.section-tarifs {
  padding-top: 30px;
  margin-top: 0;
  margin-bottom: 120px;
  @media (max-width: $max_md) {
      margin-bottom: 60px;
  }
  @media (max-width: $max_sm) {
      margin-bottom: 40px;
  }
  .section-title {
    margin-bottom: 40px;
    @media (max-width: $max_sm) {
      margin-bottom: 30px;
    }
  }
  .tarfis-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: -30px -15px;
    margin-top: 0;
    @media (max-width: $max_lg) {
      display: block;
    }
    @media (max-width: $max_sm) {
      margin-bottom: -20px;
    }
  }
  .tar-col {
    width: calc(100% / 3);
    padding: 30px 15px;
    padding-top: 0;
    @media (max-width: $max_lg) {
      width: 100%;
      max-width: 420px;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: $max_sm) {
      padding-bottom: 20px;
    }
  }
  .tar-card {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    height: 100%;
    & > * {
      width: 100%;
    }
  }
  .tar-head {
    border-radius: 3px 3px 0 0;
    padding: 35px 25px;
    position: relative;
    @media (max-width: $max_sm) {
      padding-top: 29px;
      padding-bottom: 29px;
    }
  }
  .is-best .tar-head {
    padding-top: 45px;
    padding-bottom: 25px;
  }
  .tar-body {
    padding: 35px 25px;
    @media (max-width: $max_sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: $max_xs) {
      padding: 35px 20px;
      padding-bottom: 20px;
    }
  }
  .tar-foot {
    margin-top: auto;
    padding: 35px 25px;
    padding-top: 0;
    @media (max-width: $max_sm) {
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .tar-name {
    margin-bottom: 10px;
  }
  .tar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 20px;
      @media (max-width: $max_xs) {
        margin-bottom: 15px;
      }
    }
    .count {
        width: 32px;
    }
    .tar-ico {
        margin-right: 17px;
    }
    .text {
      margin-right: auto;
      display: block;
      padding-right: 20px;
    }
  }

  .buy-tar-btn {
    width: 100%;
  }

  .tooltip {
    width: 200px;
  }

  .best-tar-tag {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    border-radius: 0 0 5px 5px;
    padding: 6px 10px;
  }
  .bg-orange .best-tar-tag {
    background-color: lighten($c-orange, 10%);
  }
  .bg-lred .best-tar-tag {
    background-color: lighten($c-lred, 10%);
  }
  .bg-green .best-tar-tag {
    background-color: lighten($c-green, 10%);
  }
}
