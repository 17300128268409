* {
  box-sizing: border-box;
}
body {
  color: $c-black;
  font-size: 14px;
  line-height: 1.2;
  font-family: "IBM Plex Sans";
  // @media (max-width: $max_xs) {
  // font-size: 13px;
  // }
}
body,
html {
  min-height: 100vh;
}
html {
  margin-top: 0 !important;
}

table {
  border-collapse: collapse;
}

.toggle-collapse[data-breakpoint-max="768"] {
  @media (min-width: $min_sm) {
    .action-item {
      display: none;
    }
    cursor: auto;
    pointer-events: all;
    user-select: auto;
  }
}

::selection {
  background-color: rgba($c-blue, 0.8);
  color: #fff;
}

a,
a:visited {
  text-decoration: none;
  color: $c-black;
  transition: 0.2s;
  &:focus {
    outline: none !important;
  }
}

.like-border {
  display: block;
  width: 100%;
  height: 0;
  border-top: 1px solid #e0e1e4;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

[data-href] {
  cursor: pointer;
}

[data-focus="scale-shadow"] {
  .notouch &.has-focus {
    transform: scale(0.98) !important;
    box-shadow: none !important;
  }
}

.dash-underline {
  display: inline-block;
  position: relative;
  padding-bottom: 3px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    border-bottom: 1px dashed $c-dgrey;
  }
}

.tooltip {
  background-color: #fff;
  color: $c-black;
  line-height: 1.5;
  font-size: 12px;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 30;
  transition: 0.15s;
  width: 200px;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &:not(.visible) {
    display: none;
  }
  &.visible {
    opacity: 0;
  }
  &.right {
    transform: translateX(15px);
    &:before {
      border-width: 7.5px 10px 7.5px 0;
      border-color: transparent #fff transparent transparent;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.left {
    transform: translateX(-15px);
    &:before {
      border-width: 7.5px 0 7.5px 10px;
      border-color: transparent transparent transparent #fff;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.show {
    transform: none;
    opacity: 1;
  }
}

.tooltip-btn {
  cursor: pointer;
  transition: 0.15s;
  user-select: none;
}

.sm-content {
  max-width: 635px;
}

.md-content {
  max-width: 825px;
}

.has-left-sidebar {
  .sm-content,
  .md-content {
    @media (max-width: $max_md) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.tab {
  position: relative;
}

.op-6 {
  opacity: 0.6;
}
.no-point-events {
  pointer-events: none;
}
.action-item {
  padding: 5px;
  cursor: pointer;
  transition: 0.1s;
  .notouch &.has-focus {
    transform: scale(1.2);
  }
}

.has-ico-left {
  display: flex;
  align-items: center;
  .ico {
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 5px;
  }
}

.pt-20 {
  padding-top: 20px;
  @media (max-width: $max_sm) {
    padding-top: 12px;
  }
}

.pt-30 {
  padding-top: 30px;
}
.pt-0 {
  padding-top: 0 !important;
}

.info-table {
  width: 100%;
  &.personal-info {
      max-width: none;
      .col-label {
          width: 180px;
          white-space: nowrap;
          @media (max-width: $max_sm) {
            width: auto;
          }
      }
  }
  &.add-info-table {
      line-height: 1.5;
      max-width: none;
      .col-label {
          width: 240px;
          white-space: nowrap;
          @media (max-width: $max_sm) {
            width: auto;
          }
      }
  }

  .col-label {
    width: 180px;
    white-space: nowrap;
    padding-right: 10px;
    color: $c-dgrey;
    @media (max-width: $max_sm) {
      padding-right: 5px;
      width: auto;
    }
  }

  td {
    padding-bottom: 6px;
    @media (max-width: $max_sm) {
      padding-bottom: 5px;
    }
  }

  tr:last-child td {
    padding-bottom: 0;
  }
  + .info-table {
      margin-top: 20px;
  }
}

.hov-opacity {
  &:hover {
    opacity: 0.6;
  }
}

.ajax-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99995;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  &:not(.visible) {
    display: none;
  }
  & > * {
    flex-shrink: 0;
    margin: auto;
    width: 72px;
    height: 72px;
  }
}

.ajax-content {
  position: relative;
}

body > .ajax-preloader {
  position: fixed;
}

.page-content {
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  & > * {
    width: 100%;
  }
  .footer {
    margin-top: auto;
  }
}

.has-left-sidebar {
  display: flex;
  align-items: stretch;
  padding-top: 26px;
  padding-bottom: 80px;
  @media (max-width: $max_md) {
    padding-bottom: 60px;
  }
  @media (max-width: $max_sm) {
    padding-bottom: 40px;
  }
  .left-sidebar {
    padding-right: 15px;
    width: 270px;
    flex-shrink: 0;
    @media (max-width: $max_md) {
      display: none;
    }
  }
  .has-sidebar-content {
    @media (min-width: $min_md) {
      padding-left: 15px;
      padding-top: 22px;
    }
    width: 100%;
  }
}

.over-hidden {
  overflow: hidden;
}

.sm-hamburger {
  // padding: 0;
  width: auto;
  height: auto;
  padding: 10px;
  .hamburger-box {
    height: 14px;
  }
  .hamburger-box,
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 15px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    height: 2px;
    border-radius: 2px;
  }
  .hamburger-inner::before {
    top: 6px;
  }
  .hamburger-inner::after {
    top: 12px;
  }
  &.hamburger--slider.is-active .hamburger-inner:after {
    transform: translate3d(0, -12px, 0) rotate(-90deg);
  }
  &.hamburger--slider.is-active .hamburger-inner {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
  &.hamburger.is-active .hamburger-inner,
  &.hamburger.is-active .hamburger-inner:after,
  &.hamburger.is-active .hamburger-inner:before {
    background-color: $c-blue;
  }
}

.blue-hamburger {
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: $c-blue;
  }
}

.over-container {
  transform: translateX(-20px);
  width: calc(100% + 40px);

  padding-left: 20px !important;
  padding-right: 20px !important;

  @media (max-width: $max_sm) {
    transform: translateX(-$sm_col_width);
    width: calc(100% + #{$sm_col_width} * 2);
    padding-left: $sm_col_width !important;
    padding-right: $sm_col_width !important;
  }
}

.over-map {
  position: relative;
  cursor: pointer;
  &.active {
    &:after {
      opacity: 0;
      pointer-events: none;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.05);
    transition: 0.2s;
  }
}

.hidden-scrollbar {
  overflow: hidden;
  .scrollbar-outher {
    overflow: auto;
  }
}
.min-130 {
  min-width: 130px !important;
}
.min-255 {
  min-width: 255px !important;
}
.min-225 {
  min-width: 225px !important;
}
.min-200 {
  min-width: 200px !important;
}
.xs-100 {
  @media (max-width: $max_xs) {
    width: 100%;
  }
}

.btn,
a.btn,
button.btn {
  display: inline-block;
  position: relative;
  align-items: center;
  transition: 0.2s;
  padding: 16.5px 52px;
  border-radius: 3px;
  cursor: pointer;
  border: 2px solid transparent;
  text-align: center;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  @media (max-width: $max_xs) {
    // padding-top: 14px;
    // padding-bottom: 14px;
  }
  .ico {
    display: inline-block;
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.ico-left {
    padding-left: 34px;
  }

  &.full-btn {
    width: 100%;
  }

  @media (max-width: $max_xs) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &:focus {
    outline: none;
  }

  .arrow {
    display: none;
    width: 14px;
    height: 13px;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s;
    z-index: 2;
    pointer-events: none;
    .svg-stroke {
      stroke: #fff;
    }
    svg {
      transform: translateY(1px);
    }
  }

  &.sm-btn {
    padding-top: 11.5px;
    padding-bottom: 11.5px;
  }

  &.arrow-btn {
    text-align: left;
    padding-right: 67px;
    padding-left: 34px;
    .arrow {
      display: inline-block;
    }
  }

  &.disabled,
  &.in-action {
    cursor: auto;
    .ink {
      display: none;
    }
  }

  &:not(.disabled):not(.in-action) {
    &:hover .arrow {
      transform: translate(5px, -50%);
    }
  }

  &.blue-btn {
    background-color: $c-blue;
    color: #fff;
    &:not(.disabled):not(.in-action) {
      &:hover {
        background-color: #3f9eff;
      }
      &.has-focus {
        background-color: #0c77e2;
      }
      &:focus {
        border-color: #aabde7;
      }
      .ink {
        background-color: rgba($c-blue, 0.4);
      }
    }
    &.disabled,
    &.in-action {
      background-color: #80bfff;
    }
  }

  &.gold-btn {
    background-color: $c-gold;
    color: #fff;
    &:not(.disabled):not(.in-action) {
      &:hover {
        background-color: #ffd02b;
      }
      &.has-focus {
        background-color: #f7c000;
      }
      &:focus {
        border-color: #aabde7;
      }
      .ink {
        background-color: #ffe794;
      }
    }
    &.disabled,
    &.in-action {
      background-color: #ffe794;
    }
  }

  &.border-btn {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    color: $c-blue;
    &:not(.disabled):not(.in-action) {
      &:hover {
        background-color: #f7fcff;
      }
      &.has-focus {
        background-color: #f7fcff;
        border-color: #e1e1e1;
      }
      &:focus {
        border: 1px solid #aabde7;
        box-shadow: 0 0 0 1px #aabde7;
      }
    }
    &.disabled,
    &.in-action {
      background-color: #fff;
      border-color: #e1e1e1;
      color: #80bfff;
      .svg-stroke {
        stroke: #80bfff;
      }
    }
    .svg-stroke {
      stroke: $c-blue;
    }
  }

  &.btn-show-more {
    color: $c-blue;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    padding: 16px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    &:not(.disabled):not(.in-action) {
      .ink {
        background-color: #e1e1e1;
      }
      &:hover {
        opacity: 0.6;
      }
    }
    &.disabled,
    &.in-action {
      opacity: 0.4;
    }
  }

  .text {
    position: relative;
    z-index: 5;
    pointer-events: none;
  }
}
.btn-link {
  display: inline-block;
  background-color: transparent;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 35px;
  margin: -6px;
  margin-top: 0;
  flex-wrap: wrap;
  @media (max-width: $max_sm) {
    padding-top: 25px;
  }
}

.pag-item {
  padding: 6px;
  padding-top: 0;
}

.pag-link {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #e1e1e1;

  font-size: 13px;
  &.current-link {
    background-color: $c-blue;
    color: #fff;
  }
  &.more-link {
    min-width: auto;
    border: none;
    padding-left: 9px;
    padding-right: 9px;
  }
  &.prev-link svg {
    transform: rotate(90deg);
  }
  &.next-link svg {
    transform: rotate(-90deg);
  }
}

.socials-list {
  padding-top: 25px;
  display: flex;
  align-items: center;
  margin: -20px -10px;
  margin-top: 0;
  .social-item {
    padding: 20px 10px;
    padding-top: 0;
  }
  .soc-link {
    display: inline-block;
    &:hover {
      opacity: 0.6;
    }
  }
}

.tag-btn {
  border: none;
  cursor: auto;
  pointer-events: none;
  padding: 10px;
  border-radius: 3px;
  font-size: 14px;
  @media (max-width: $max_sm) {
    font-size: 13px;
  }
  &.tag-grey {
    background-color: #f7f7f8;
    color: #9c9b9b;
  }
  &.tag-red {
    background-color: #ffe5e6;
  }
  &.tag-yellow {
    background-color: #fff8aa;
  }
}

.collapse {
  &:not(.active) {
    display: none;
  }
}

.toggle-collapse {
  cursor: pointer;
  user-select: none;
  position: relative;
  .ico {
    transition: 0.2s;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
  }
  &.active .ico {
    transform: rotate(180deg);
  }
}

.rounded-btn {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #bcc8db;
  transition: 0.2s;
  .ico {
    width: 16px;
  }
  &.add-btn {
    padding-top: 3px;
  }
  @media (max-width: $max_sm) {
    width: 40px;
    height: 40px;
    .ico {
      width: 12px;
    }
    &.add-btn {
      padding-top: 1px;
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.alert-modal {
  display: none;
}

.material-btn {
  position: relative;
  overflow: hidden;
  .ink {
    display: block;
    position: absolute;
    border-radius: 100%;
    transform: scale(0);
  }
  .animate {
    animation: ripple 0.65s linear;
  }
  & > * {
    pointer-events: none;
  }
}
.ink {
  background-color: rgba(#e1e1e1, 0.3);
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

.js-bottom-arrow {
  position: relative;
}

.bottom-arrow {
  transition: 0.2s;
  display: block;
  position: absolute;
  bottom: 0;
  width: 0px;
  height: 4px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: $c-blue;
}

.js-add-from-template {
  .add-link {
    font-size: 15px;
    display: inline-block;
    .add-sign {
      display: inline-block;
      padding-top: 2px;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  .template {
    display: none;
  }
}

.template-cloned {
  position: relative;
  padding-top: 10px;
  .delete-template {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
  }
}

.bg-grey {
  background-color: $c-lgrey;
}
.bg-gold {
  background-color: $c-gold;
}
.bg-white {
  background-color: #fff;
}
.bg-orange {
  background-color: $c-orange;
}
.bg-red {
  background-color: $c-red;
}
.bg-lred {
  background-color: $c-lred;
}
.bg-green {
  background-color: $c-green;
}

.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  &,
  img {
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
  }
  background-repeat: no-repeat;
  background-size: cover;
}

.has-under-row {
  position: relative;
  padding-bottom: 20px;
  @media (max-width: $max_md) {
    padding-bottom: 20px;
  }
  .under-row {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 10px;
    background-color: $c-gold;
    width: 80%;
    border-radius: 2px;
    @media (max-width: $max_md) {
      height: 7px;
    }
    @media (max-width: $max_sm) {
      height: 5px;
    }
  }
}

.page-title {
  margin: 0;
}

.btn-row {
  display: flex;
  align-items: center;
  &.center {
    justify-content: center;
  }
}

.blue-link {
  display: flex;
  align-items: center;
  color: $c-blue !important;
  &:hover {
    opacity: 0.6;
  }
  transition: 0.2s;
  &:hover svg {
    transform: translateX(10px);
  }
  svg {
    margin-top: 5px;
    transition: 0.2s;
  }
  .svg-stroke {
    stroke: $c-blue;
  }
  .text {
    display: inline-block;
    margin-right: 10px;
  }
}

a.left-ico {
  display: flex;
  align-items: center;
  .ico {
    margin-right: 10px;
  }
}

.left-arrow {
  position: relative;
  padding-left: 25px;
  @media (max-width: $max_sm) {
    padding-left: 20px;
  }
  .arrow {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: $c-red;
    &.blue-arrow {
      background-color: $c-blue;
    }
  }
}

.hov-shadow {
  transition: 0.2s;
  &:hover,
  .touch &.touch-shadow {
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.06);
  }
}

.slick-track {
  display: flex;
  align-items: stretch;
}

.slick-slide {
  &:focus {
    outline: none !important;
  }
  height: auto;
}

.card {
  @extend .hov-shadow;
  @extend .br-4;
  @extend .bg-white;
  height: 100%;
  transition: 0.1s;
  .arrow-ico {
    transition: 0.1s;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: -2px;
    transform: translateX(-15px);
    svg {
      width: 18px;
    }
    .touch & {
      display: none;
    }
  }
  &:hover {
    .arrow-ico {
      transform: none;
      opacity: 1;
    }
  }
}

.bottom-go-back {
  .content {
    padding-bottom: 50px;
    padding-top: 35px;
    display: flex;
    width: 100%;
    border-top: 1px solid #e0e1e4;
    @media (max-width: $max_sm) {
      padding-top: 30px;
      padding-bottom: 40px;
    }
  }
  .back-link {
    display: flex;
    align-items: center;
    &:hover .ico {
      transform: translateX(-5px);
    }
    .ico {
      width: 5px;
      transition: 0.15s;
    }
    .text {
      display: inline-block;
      padding-left: 10px;
    }
  }
}

.br-4 {
  border-radius: 4px;
}

.ico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  svg {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100% !important;
  }
  &.md-ico {
    width: 17px;
  }
  &.sm-ico {
    width: 15px;
  }
  &.xs-ico {
    width: 10px;
  }
  &.xxs-ico {
    width: 8px;
  }
}

.section-title {
  &.ico-right {
    display: flex;
    align-items: center;
    .ico {
      margin-left: 10px;
    }
  }
  &.img-left {
    display: flex;
    align-items: center;
    img {
      flex-shrink: 0;
      flex-grow: 0;
      width: 66px;
      margin-right: 20px;
      @media (max-width: $max_sm) {
        width: 30px;
        margin-right: 15px;
      }
    }
  }
}

.triangle-banner {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .img-col {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    img {
      max-height: 100%;
    }
  }
  .content-col {
    position: relative;
    z-index: 2;
    padding: 44px 64px;
    padding-right: 58px;
    width: 390px;
    margin-right: auto;
    &.has-gold-triangle {
      @media (min-width: $min_md) {
        background-color: $c-gold;
        border-radius: 0 40px 40px 0;
      }
    }
  }
  .bg-triangle,
  .bg-triangle-fff {
    position: absolute;
    top: 0;
    left: 21px;
    width: 100%;
    height: 100%;
    z-index: -1;
    svg {
      height: 100%;
      // width: auto;
    }
  }
  .bg-triangle-fff {
    left: 65px;
    // svg {
    // display: none;
    // }
  }
  .title {
    margin-bottom: 15px;
  }
  .desc {
    margin: 0;
  }
  .btn-row {
    padding-top: 30px;
  }
  @media (max-width: $max_md) {
    .content-col {
        padding-top: 29px;
        padding-bottom: 29px;
    }
    .bg-triangle {
        left: -20px;
    }
    .bg-triangle-fff {
        left: 25px;
    }
  }
}

.triangle-right-banner {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  .input {
    background-color: transparent;
  }
  .content-col {
    padding: 60px 95px;
    max-width: 660px;
    padding-right: 0;
    width: 100%;
    padding-right: 95px;
    position: relative;
    z-index: 2;
  }
  .form-row {
    padding-top: 30px;
  }
  .title {
    margin-bottom: 15px;
    @media (max-width: $max_sm) {
      margin-bottom: 10px;
    }
  }
  .btn-row {
    padding-top: 30px;
    @media (max-width: $max_sm) {
      padding-top: 20px;
    }
  }
  .img-col {
    position: relative;
    width: 400px;
    flex-shrink: 0;
    &.has-triangle-right {
      @media (min-width: $min_md) {
        background: linear-gradient(
          to left,
          #{$c-gold},
          #{$c-gold} 50%,
          transparent 51%,
          transparent
        );
      }
    }
  }
  .bg-triangle {
    position: absolute;
    top: 0;
    right: 38px;
    height: 100%;
    svg {
      transform: scale(1.1);
      height: 100%;
    }
  }
  .img-wrap {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 5;
    transform: translate(-40%, -50%);
    img {
      width: 400px;
    }
  }

  .on-triangle-img {
    transform: translate(50%, -50%);
    img {
      width: 230px;
    }
  }
  @media (max-width: $max_md) {
      .bg-triangle {
          right: 20px;
      }
      .content-col {
          padding: 30px 50px 30px 30px;
      }
      .form-row {
          flex-direction: column;
          .btn-wrap {
              margin-top: 8px;
              padding-left: 0;
              width: 100%;
              .btn {
                  width: 100%;
              }
          }
      }
  }
  @media (max-width: $max_sm) {
      .bg-triangle {
          right: -120px;
      }
      .img-col {
          width: 240px;
      }
      .on-triangle-img img {
          width: 160px;
      }
  }
}

.inline-svg-link {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.over-gradient {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(48, 41, 41, 0.2), transparent);
  }
}

.display-lg {
  @media (max-width: $max_lg) {
    display: none !important;
  }
}

.display-sm {
  @media (max-width: $max_sm) {
    display: none !important;
  }
}

.display-md {
  @media (max-width: $max_md) {
    display: none !important;
  }
}
.display-xs {
  @media (max-width: $max_xs) {
    display: none !important;
  }
}

.hide-lg {
  @media (min-width: $min_lg) {
    display: none !important;
  }
}
.hide-sm {
  @media (min-width: $min_sm) {
    display: none !important;
  }
}
.hide-md {
  @media (min-width: $min_md) {
    display: none !important;
  }
}
.hide-xs {
  @media (min-width: $min_xs) {
    display: none !important;
  }
}

.rounded-img {
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.collapse-btn {
  position: relative;
  cursor: pointer;
  padding-right: 30px;

  &:after {
    content: "";
    width: 14px;
    height: 7px;
    background-image: url("../img/svg/row-down.svg");
    background-position: center center;
    background-repeat: no-repeat;

    transition: 0.2s;
    position: absolute;
    top: 5px;
    right: 0;
  }

  &.active:after {
    transform: rotate(180deg);
  }
}

.page-breadcrumbs {
  padding-top: 26px;
  font-size: 12px;
  .container {
    overflow: hidden;
    overflow-x: auto;
  }
  &.bordered {
    padding-bottom: 26px;
    border-bottom: 1px solid #e0e1e4;
  }
  .breadcrumb-item,
  .separator {
    color: #9c9b9b;
  }
  .breadcrumb-item:hover:not(.current) {
    opacity: 0.6;
  }
  .separator {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.small-cards-list {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: -25px -11px;
  margin-top: 0;
  @media (max-width: $max_xs) {
    margin-bottom: -12px;
  }
  .cat-col {
    width: 20%;
    padding: 25px 11px;
    padding-top: 0;
    @media (max-width: $max_md) {
      width: 25%;
    }
    @media (max-width: $max_sm) {
      width: 50%;
    }
    @media (max-width: $max_xs) {
      width: 100%;
      padding-bottom: 12px;
    }
  }
  .cat-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    border: 1px solid #e6e6e6;
    padding: 20.5px 12px;
    & > * {
      width: 100%;
    }
    &:hover {
      border-color: transparent;
    }
    .touch & {
      box-shadow: none;
    }
    @media (max-width: $max_xs) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 18px 15px;
      & > * {
        width: auto;
      }
    }
  }
  .thumb {
    height: 65px;
    display: flex;
    font-size: 60px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-bottom: auto;
    img {
      flex-shrink: 0;
      max-height: 100%;
      max-width: 100%;
    }
    @media (max-width: $max_xs) {
      margin: 0;
      width: 30px;
      height: auto;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .content {
    padding-top: 25px;
    height: 100%;
    margin-top: auto;

    display: flex;
    flex-direction: column;

    @media (max-width: $max_xs) {
      padding-top: 0;
      padding-left: 17px;
      display: block;
      text-align: left;
      height: auto;
      margin: 0;
    }
  }

  .vac-count {
    padding-top: 12px;
    margin-top: auto;
    @media (max-width: $max_xs) {
      margin-top: 0;
      padding-top: 8px;
    }
  }
}

.page-title-section {
  .page-title {
    margin: 0;
  }
  padding-top: 40px;
  padding-bottom: 40px;
  @media (min-width: $min_sm) {
    border-bottom: 1px solid #e0e1e4;
  }
  @media (max-width: $max_sm) {
    padding-bottom: 0;
  }
}

.hr-text {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  .text {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fff;
    color: #9c9b9b;
    white-space: nowrap;
    font-size: 13px;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    width: 50%;
    height: 1px;
    top: 50%;
    background-color: #e1e1e1;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
}

/*==============================================================
*									SUMMARY / VACANCY SIDEBAR CARDS
=============================================================== */

.about-comp-card,
.personal-card,
.vac-control-card {
  @media (min-width: $min_md) {
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    padding: 30px 25px;
  }
  background-color: #fff;
  .block-title {
    margin-bottom: 25px;
  }
  .avatar img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .avatar {
    flex-shrink: 0;
    flex-grow: 0;

    position: relative;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #bcc8db;
  }

  .btn {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.personal-card {
  display: flex;
  flex-direction: column;
  align-items: center;

  .name {
    padding-top: 20px;
  }

  .contacts-block {
    margin-bottom: -15px;

    .block-label,
    .contact-item {
      margin-bottom: 8px;
    }
  }

  .notar-block,
  .contacts-block {
    width: 100%;
    padding-top: 25px;
    margin-top: 25px;
    border-top: 1px solid #e6e6e6;
    padding-bottom: 10px;
  }

  .notar-block {
    padding-left: 10px;
    padding-right: 10px;
  }

  .views {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .btn-row {
    width: 100%;
    padding-top: 15px;
  }

  .controls {
    width: 100%;
    text-align: center;
    padding-top: 25px;

    .divider {
      display: inline-block;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .body-block {
    width: 100%;
  }

  @media (min-width: $min_md) {
    .head-block {
      .salary {
        display: none;
      }
    }
  }

  @media (max-width: $max_md) {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    padding-bottom: 25px;
    border: none;
    .head-block {
      display: flex;
      width: 100%;
    }

    .print-col {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    .print-page-link {
      display: block;
      flex-shrink: 0;
    }

    .avatar {
      flex-shrink: 0;
      margin-right: 15px;
      margin-left: 0;
    }
    .name {
      padding-top: 10px;
    }

    .head-block {
      .salary {
        padding-top: 13px;
      }
    }
    .notar-block {
      padding-left: 0;
      padding-right: 0;
      padding-top: 20px;
      width: calc(100% - 40px);
      margin-left: auto;
      margin-right: auto;
    }

    .meta-row {
      padding-top: 20px;
    }
    .meta-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: $max_sm) {
    .avatar {
      width: 55px;
      height: 55px;
    }
    .name {
      padding-top: 3px;
    }
  }
}

.vac-control-card {
  @media (min-width: $min_md) {
    text-align: center;
    .desc {
      max-width: 170px;
      margin: 0 auto;
      margin-bottom: 15px;
      line-height: 1.5;
    }
  }
  .meta {
    margin-bottom: 30px;
    @media (max-width: $max_sm) {
      margin-bottom: 15px;
    }
  }

  .btn {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-title {
    padding-bottom: 25px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 25px;
  }
  .views {
    margin-bottom: 15px;
  }

  .btn-row {
    padding-top: 15px;
  }
  .border-btn {
    color: $c-black !important;
  }
  .controls {
    padding-top: 25px;
    .divider {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (max-width: $max_md) {
      padding-top: 15px;
      text-align: center;
    }
  }
}

.vac-control-card,
.about-comp-card {
  .meta-item {
    display: flex;
    justify-content: space-between;
    line-height: 1.5;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  .meta-block-title {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .meta-val {
    max-width: 55%;
    text-align: right;
  }
}

.about-comp-card {
  @media (min-width: $min_md) {
    text-align: center;
    .avatar {
      margin-bottom: 20px;
    }
  }

  @media (max-width: $max_md) {
    .card-head {
      display: flex;
    }
    .avatar {
      margin: 0;
    }
    .head-content {
      padding-left: 15px;
      padding-top: 10px;
    }
    .com-name {
      font-size: 20px;
    }
  }

  @media (max-width: $max_sm) {
    .avatar {
      width: 55px;
      height: 55px;
    }
    .head-content {
      padding-left: 10px;
      padding-top: 2px;
    }
  }

  .meta {
    margin-bottom: 25px;
  }

  .com-name {
    margin-bottom: 10px;
  }
  .contacts-block {
    text-align: left;
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #e6e6e6;

    @media (max-width: $max_md) {
      margin-top: 0;
      transform: translateX(-20px);
      width: calc(100% + 40px);
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: $max_sm) {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
      padding-left: $sm_col_width;
      padding-right: $sm_col_width;
    }
  }
  .block-title {
    margin-bottom: 15px;
  }
  .contact-item:not(:last-child) {
    margin-bottom: 10px;
  }
  .btn-row {
    padding-top: 25px;
    @media (max-width: $max_md) {
      padding-top: 0;
      margin-bottom: 25px;
    }
  }
}

/*==============================================================
*												FONTS
=============================================================== */
.lh-3 {
  line-height: 1.3;
}
.lh-4 {
  line-height: 1.4;
}
.lh-5 {
  line-height: 1.5;
}
.lh-6 {
  line-height: 1.6;
}
.fw-normal {
  font-weight: normal;
}
.fw-bold {
  font-weight: bold;
}
.fw-500 {
  font-weight: 500;
}
.fw-600,
.btn.fw-600,
a.btn.fw-600 {
  font-weight: 600;
}
.tt-upper {
  text-transform: uppercase;
}
.ta-center {
  text-align: center;
}
.ta-right {
  text-align: right;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
  @media (max-width: $max_xs) {
    font-size: 13px;
  }
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
  @media (max-width: $max_sm) {
    font-size: 15px;
  }
}
.fs-20 {
  font-size: 20px;
  @media (max-width: $max_sm) {
    font-size: 18px;
  }
  @media (max-width: $max_xs) {
    font-size: 15px;
  }
}
.fs-22 {
  font-size: 22px;
  @media (max-width: $max_sm) {
    font-size: 20px;
  }
  @media (max-width: $max_xs) {
    font-size: 18px;
  }
}
.fs-24 {
  font-size: 24px;
  @media (max-width: $max_md) {
    font-size: 22px;
  }
  @media (max-width: $max_sm) {
    font-size: 20px;
  }
}
.fs-28 {
  font-size: 28px;
  @media (max-width: $max_md) {
    font-size: 26px;
  }
  @media (max-width: $max_sm) {
    font-size: 24px;
  }
  @media (max-width: $max_xs) {
    font-size: 20px;
  }
}
.fs-30 {
  font-size: 30px;
}
.fs-36 {
  font-size: 36px;
  @media (max-width: $max_sm) {
    font-size: 24px;
  }
}
.fs-44 {
  font-size: 44px;
  @media (max-width: $max_md) {
    font-size: 38px;
  }
}

.c-white,
a.c-white {
  color: #fff;
}
.c-black,
a.c-black,
.btn.c-black,
a.btn.c-black {
  color: $c-black;
}
.c-blue,
a.c-blue {
  color: $c-blue;
}
.c-grey3,
a.c-grey3 {
  color: $c-grey3;
}
.c-dgrey,
a.c-dgrey {
  color: $c-dgrey;
}
.c-grey4,
a.c-grey4 {
  color: $c-grey4;
}
.c-green,
a.c-green {
  color: $c-green;
}

/*==============================================================
*												GRID
=============================================================== */

.container {
  width: 100%;
  max-width: 1110px + $col_width * 2;
  margin-left: auto;
  margin-right: auto;
  padding-left: $col_width;
  padding-right: $col_width;
  @media (max-width: $max_sm) {
    max-width: 1110px + $sm_col_width * 2;
    padding-left: $sm_col_width;
    padding-right: $sm_col_width;
  }
  &.lg-container {
    max-width: 1440px + $col_width * 2;
  }
  &.md-container {
    max-width: 950px + $col_width * 2;
    @media (max-width: $max_sm) {
      max-width: 950px + $sm_col_width * 2;
    }
  }
  &.full-container {
    max-width: 100%;
  }
}
.row {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-left: -$col_width;
  margin-right: -$col_width;
  margin-bottom: -$col_width * 2;
  & > * {
    padding-left: $col_width;
    padding-right: $col_width;
    padding-bottom: $col_width * 2;
  }
  @media (max-width: $max_sm) {
    margin-left: -$sm_col_width;
    margin-right: -$sm_col_width;
    margin-bottom: -$sm_col_width * 2;
    & > * {
      padding-left: $sm_col_width;
      padding-right: $sm_col_width;
      padding-bottom: $sm_col_width * 2;
    }
  }
}
.section {
  position: relative;
  margin-top: 64px;
  margin-bottom: 60px;
  @media (max-width: $max_sm) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  &.bg-grey {
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 64px;
    padding-bottom: 60px;
    @media (max-width: $max_sm) {
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 40px;
        padding-bottom: 40px;
    }
  }
}

/*==============================================================
*												REGISTER BANNER
=============================================================== */
.register-banner {
  .bg-triangle,
  .bg-triangle-fff {
    @media (max-width: $max_md) {
      display: none;
    }
  }
  .content-col {
    @media (max-width: $max_md) {
      width: 100%;
      max-width: 390px;
    }
    @media (max-width: $max_sm) {
      padding: 40px 30px;
    }
    @media (max-width: $max_xs) {
      max-width: 100%;
    }
  }
  .bg-img {
    @media (max-width: $max_md) {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(44, 45, 53, 0.4);
      }
    }
  }
  .title {
    @media (max-width: $max_sm) {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }
  .btn {
    @media (max-width: $max_xs) {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

/*==============================================================
*												VAC BANNER
=============================================================== */
.vac-banner {
  @media (max-width: $max_xl) {
    .content-col {
      padding-left: 60px;
    }
    .img-col {
      transform: translateX(50px);
    }
  }
  @media (max-width: $max_md) {
    flex-direction: column-reverse;
    overflow: visible;
    .img-col {
      transform: none;
      height: 400px;
      overflow: hidden;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: -100px;
        height: calc(100% + 100px);
        left: 50%;
        width: 660px;
        transform: translate(-50%);

        background-position: top center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      &:after {
        background-image: url("../img/svg/yellow-triangle.svg");
      }
      &:before {
        background-image: url("../img/svg/white-triangle.svg");
      }
    }
    .img-wrap {
      bottom: 49px;
      left: 50%;
      top: auto;
      transform: translate(-54%, 0);
    }
    .content-col,
    .img-col {
      width: 100%;
    }
    .bg-triangle {
      transform: rotate(-90deg);
      width: 100%;
      display: none;
    }
    .content-col {
      padding: 0 20px;
    }
  }

  @media (max-width: $max_xs) {
    transform: translateX(-$sm_col_width);
    width: calc(100% + #{$sm_col_width} * 2);
    .img-col {
      margin-bottom: 15px;
    }
    .img-wrap img {
      width: 240px;
    }

    .img-wrap {
      bottom: 11px;
    }

    .img-col {
      height: 226px;
      &:after,
      &:before {
        top: -216px;
        height: 460px;
      }
    }
    .btn {
      width: 100%;
    }
  }
}

/*==============================================================
*												TABS
=============================================================== */
.tabs-links-wrap {
  display: flex;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  @media (max-width: $max_sm) {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  &.tabs-bottom-arrow {
    .tab-link {
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transition: 0.2s;
        opacity: 0;
        height: 4px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: $c-blue;
      }
      &.active:after {
        opacity: 1;
      }
    }
  }
}
.tab-link-item {
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: $max_sm) {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}
.tab-link {
  display: block;
  padding-bottom: 15px;
  &.disabled {
      cursor: default;
      opacity: .5;
  }
  &,
  .c-blue {
    color: $c-dgrey !important;
  }
  &.active {
    color: $c-black !important;
    .c-blue {
      color: $c-blue !important;
    }
  }
  @media (max-width: $max_sm) {
    padding-bottom: 12px;
  }
}

/*==============================================================
*												LOOP NEWS CARDS
=============================================================== */
.news-card {
  display: flex;
  flex-direction: column;

  .thumb {
    height: 158px;
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;
  }

  .thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content-padding {
    padding: 30px;
    height: 100%;
  }

  .content-wrap {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .date {
    margin-top: auto;
  }
  .title {
    margin-bottom: 18px;
  }
  @media (max-width: $max_md) {
    .thumb {
      height: 220px;
    }
  }
  @media (max-width: $max_sm) {
    .title {
      font-size: 16px;
    }
    .date {
      font-size: 12px;
    }
  }
  @media (max-width: $max_xs) {
    .thumb {
      height: 35vw;
    }
    .content-padding {
      padding: 15px 10px;
    }
  }
}

.news-col {
  width: calc(100% / 3);
  padding: 30px 15px;
  padding-top: 0;
  @media (max-width: $max_md) {
    width: 50%;
  }
  @media (max-width: $max_sm) {
    width: 100%;
    max-width: 576px;
    margin-left: auto;
    margin-right: auto;
  }
}
.news-row {
  margin: -30px -15px;
  margin-top: 0;
}

/*==============================================================
*												EDITOR CONTENT
=============================================================== */
.editor-content {
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: -20px;
  @media (max-width: $max_sm) {
    margin-bottom: -15px;
  }
  @media (max-width: $max_xs) {
    font-size: 14px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.25;
    margin-bottom: 0.7em;
  }

  h1 {
    font-size: 2.4em;
    @media (max-width: $max_md) {
      font-size: 2em;
    }
    @media (max-width: $max_sm) {
      font-size: 1.5em;
    }
  }

  h2 {
    font-size: 1.86em;
    @media (max-width: $max_md) {
      font-size: 1.6em;
    }
    @media (max-width: $max_sm) {
      font-size: 1.3em;
    }
  }

  p,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 20px;
    @media (max-width: $max_sm) {
      margin-bottom: 10px;
    }
  }

  img {
    max-width: 100%;
    height: auto !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: $max_xs) {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
      max-width: none;
    }
  }
}

/*==============================================================
*												NEWS SLICK
=============================================================== */
.news-slick {
  .slick-track {
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 20px;
    padding-bottom: 30px;
    position: relative;
    z-index: 20;
  }
  .slick-item {
    padding: 0 20px;
  }
  @media (max-width: $max_sm) {
    .slick-track {
      margin-left: -15px;
      margin-right: -15px;
    }
    .slick-item {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

/*==============================================================
*												MODALS
=============================================================== */
.modal {
  display: none;
  width: 100%;
  max-width: 920px;

  padding: 45px;
  padding-top: 60px;

  .modal-title {
    margin-bottom: 30px;
  }

  .modal-subtitle {
    margin-bottom: 8px;
    margin-top: -20px;
  }

  &.sm-modal {
    max-width: 635px;
  }
  @media (max-width: $max_sm) {
    padding: 40px 15px;
  }
}
.login-modal {
  .form-col {
    margin-bottom: 10px;
  }
  .forget-pass {
    margin-bottom: 0;
    margin-top: -10px;
    padding-left: 12.5px;
    padding-right: 12.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      margin: 0;
      padding: 0;
      flex: none;
    }
  }
  .btn-row {
    padding-top: 40px;
  }
  .submit-btn {
    width: 100%;
    max-width: 235px;
  }

  .hr-text {
    transform: translateX(-45px);
    width: calc(100% + 90px);
  }
  @media (min-width: $min_md) {
    padding: 0;

    .modal-content {
      display: flex;
      align-items: stretch;
    }
    .login-col,
    .soc-col {
      width: 50%;
      padding: 60px 35px 40px 45px;
    }
    .soc-col {
      padding-left: 35px;
      border-left: 1px solid #e1e1e1;
    }
    .soc-btns-wrap {
      padding-top: 35px;
    }
  }

  @media (max-width: $max_md) {
    .soc-col {
      padding-top: 40px;
      width: 100%;
      overflow: hidden;
    }
    .hr-text {
      margin-bottom: 40px;
    }
    .btn-row {
      padding-top: 25px;
    }
    .soc-btns-wrap {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      margin: -22px;
      margin-top: 0;
      overflow: hidden;
      .soc-btn-row {
        margin: 0;
        padding: 22px;
        padding-top: 0;
      }
    }
  }
  @media (max-width: $max_sm) {
    .hr-text {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
    }
  }
  @media (max-width: $max_xs) {
    .btn[type="submit"] {
      width: 100%;
      max-width: 100%;
    }
  }
}

.soc-login-btn,
a.btn.soc-login-btn,
.btn.soc-login-btn {
  display: flex;
  align-items: stretch;
  padding: 10px;
  padding-left: 0;
  border-radius: 3px;
  & > * {
    display: flex;
    align-items: center;
  }
  .text {
    display: inline-block;
    padding: 10px 15px;
    color: #fff;
    font-weight: 600;
  }
  .ico {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50px !important;
    svg {
      width: auto !important;
    }
    border-right: 1px solid rgba(52, 54, 60, 0.2);
  }
  &:hover {
    opacity: 0.8;
  }
  &.vk-login {
    background-color: #4a76a8;
  }
  &.fb-login {
    background-color: #4267b2;
  }
  &.ok-login {
    background-color: #ff9800;
  }
}

.soc-btn-row:not(:last-child) {
  margin-bottom: 20px;
}

.confirm-modal {
  text-align: center;

  @media (max-width: $max_sm) {
    .fs-18 {
      font-size: 18px;
    }
    .fs-16 {
      font-size: 16px;
    }
  }
  @media (max-width: $max_xs) {
    .fs-18 {
      font-size: 18px;
    }
    .fs-16 {
      font-size: 16px;
    }
  }
  .btn {
    margin: 7.5px;
    padding-left: 15px;
    padding-right: 15px;
    @media (max-width: $max_xs) {
      margin: 0;
      width: 100%;
    }
  }
  .btn-row {
    margin: -7.5px;
    padding-top: 30px;
    @media (max-width: $max_xs) {
      margin: 0;

      &.two-btns {
        justify-content: space-between;
        .btn {
          width: calc(50% - 15px / 2);
        }
      }
    }
  }

  .text {
    // margin-bottom: 30px;
    p {
      margin-bottom: 7px;
      &:last-child {
        margin: 0;
      }
    }

    @media (max-width: $max_xs) {
      margin-bottom: 70px;
    }
  }

  .thumb {
    margin-bottom: 40px;
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 110px;
      max-width: 110px;
      @media (max-width: $max_xs) {
        max-width: 80px;
      }
    }
    @media (max-width: $max_xs) {
      padding-top: 60px;
    }
  }

  .circle-shadow {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: rgba(176, 186, 200, 0.1);
      @media (max-width: $max_xs) {
        display: none;
      }
    }
    img {
      position: relative;
      z-index: 1;
    }

    &.left-shadow:after {
      top: 0;
      left: 0;
      transform: translate(-30%, -30%);
    }
    &.right-shadow:after {
      top: 0;
      right: 0;
      transform: translate(50%, -15%);
    }
    &.bottom-shadow:after {
      bottom: 0;
      left: 0;
      transform: translate(-40%, 25%);
    }
  }
}

.modal-forget-pass {
  @media (max-width: $max_sm) {
    .btn-row {
      padding-top: 12px;
    }
  }
}

/*==============================================================
*												VACANCIES CARD
=============================================================== */
.vac-card {
  border-radius: 3px;
  margin-bottom: 25px;

  @media (min-width: $min_sm) {
    margin-bottom: 15px;
    border: 1px solid #e1e1e1;
    padding: 30px;
    padding-right: 40px;
    padding-top: 28px;
    padding-bottom: 25px;
    &.standart,
    &.top-vac-card {
      border: 2px solid #f2bf0a;
    }
    &.premium {
        background-color: #FFD700;
        border: 1.4px solid #FFD700;
    }
  }
  @media (max-width: $max_sm) {
    &.standart,
    &.top-vac-card {
      background-color: #EEE8AA;
    }
    &.standart,
    &.premium {
        margin-left: -20px;
        margin-right: -20px;
        padding: 20px;
    }
    &.premium {
        background-color: #f2bf0a;
        border: 1.4px solid #f2bf0a;
    }
  }

  .tag-btn-row {
    padding-top: 15px;
    border: none;
    .tag-btn {
      padding-top: 15px;
      padding-bottom: 15px;
      display: block;
      text-align: left;
    }
  }
  .title {
    margin-bottom: 11px;
    &.has-tag {
      margin-bottom: 4px;
    }
    .tag-btn {
      display: inline-block;
      margin-left: 10px;
    }
  }
  .btn {
    min-width: 165px;
    padding-left: 15px;
    padding-right: 15px;
    flex-shrink: 0;
    @media (max-width: $max_xs) {
      font-size: 14px;
    }
  }
  .main-card-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  .salary {
    margin-bottom: 15px;
    @media (max-width: $max_sm) {
      margin-bottom: 15px;
    }
  }
  .meta {
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      padding-right: 3px;
    }
    @media (max-width: $max_sm) {
      display: block;
      .meta-divider {
        display: none;
      }
      .meta-item:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
  .vac-controls-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    .controls,
    .controls a {
      color: $c-dgrey;
    }
    .controls a:hover {
      opacity: 0.6;
    }
    .cont-divider {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (max-width: $max_xs) {
      display: block;
      padding-top: 15px;
      .btn {
        width: 100%;
      }
      .controls {
        padding-top: 15px;
      }
      .tag-btn {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: left;
        transform: translateX(-$sm_col_width);
        width: calc(100% + #{$sm_col_width} * 2);
        padding-left: $sm_col_width;
      }

      &.has-date {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 5px;
        .date {
          display: block;
          margin-bottom: 15px;
        }
      }
    }
  }
  .meta-divider {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    font-size: 0;
    &:before {
      content: "";
      display: block;
      width: 1px;
      height: 20px;
      background-color: rgba(106, 106, 106, 0.2);
    }
  }

  .logo-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .date {
      padding-top: 30px;
    }
    @media (max-width: $max_sm) {
      justify-content: flex-start;
      padding-top: 20px;
    }
  }

  .logo-col img {
    height: auto;
    max-width: 100px;
    @media (max-width: $max_sm) {
      max-width: 52px;
    }
  }

  .status-wrap {
    padding-top: 30px;
    @media (max-width: $max_sm) {
      padding-top: 15px;
    }
  }
  .status {
    display: inline-block;
    padding: 11px 20px;
    border-radius: 3px;
    text-align: center;
    @media (max-width: $max_sm) {
      padding: 11px;
    }
    &.refused {
      background-color: #ffe5e6;
    }
    &.invited {
      background-color: #fff8aa;
    }
    &.pending {
      background-color: #f7f7f8;
    }
  }

  .add-card-content {
    padding-top: 15px;
  }

  .read-more-mess {
    background-color: #f7f7f8;
    border-radius: 3px;
    font-size: 14px;
    @media (max-width: $max_sm) {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
    }
  }

  .toggle-collapse {
    padding: 20px;
    padding-right: 50px;
    .ico {
      top: 20px;
      right: 20px;
      transform-origin: center center;
      @media (max-width: $max_sm) {
        right: 15px;
        top: 17px;
      }
    }
    @media (max-width: $max_sm) {
      padding: 15px;
      padding-right: 40px;
    }
  }
  .collapse .content {
    padding: 20px;
    padding-top: 0;
    line-height: 1.6;
  }

  .info-row {
    margin-top: 15px;
    background-color: #f7f7f8;
    border-radius: 3px;
    color: #9c9b9b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    .text {
      padding-right: 15px;
    }
    @media (max-width: $max_xs) {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
      display: block;
      padding: 15px;
      .text {
        padding: 0;
        margin-bottom: 10px;
      }
    }
  }
}

.flex-align-center {
    align-items: center;
}

.flex-justify-between {
    justify-content: space-between;
}

.ta-right {
    text-align: right;
}

.js-accordeon-toggle {
    background: #fff;
    border-radius: 3px;
    display: inline-block;
    padding: 12px 20px;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    color: #1282f2;
    &:hover {
        box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.06)
    }
}

.js-accordeon {
    margin-top: 30px;
}
