/*==============================================================
*												SECTION VACANCIES COMPANY
=============================================================== */
.section-vacancies_company {

  @media (max-width: $max_xs) {
    .comp-row {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
    }
  }

  .vac-banner {
    @media (max-width: $max_md) {
      transform: translateX(-20px);
      width: calc(100% + 40px);
    }
  }
  .desc {
    @media (max-width: $max_sm) {
      font-size: 14px;
    }
  }
  .section-title {
    margin-bottom: 20px;
  }
  .comp-col {
    width: calc(100% / 6);
    padding: 12px;
    padding-top: 0;
    @media (max-width: $max_md) {
      width: 25%;
    }
    @media (max-width: $max_xs) {
      padding: 0 5px;
    }
  }
  .comp-row {
    margin: -12px;
    margin-top: 0;
    justify-content: center;
    @media (max-width: $max_xs) {
      margin: 0;
    }
  }
  .comp-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    & > * {
      pointer-events: none;
    }
    padding: 35px 10px;
    padding-bottom: 23px;
    @media (max-width: $max_xs) {
      padding: 15px 10px;
    }
    &:hover,
    .touch & {
      .count {
        opacity: 1;
        transform: none;
      }
    }
  }
  .thumb {
    padding-bottom: 30px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      flex-shrink: 0;
      max-width: 100%;
      max-height: 100%;
    }
    @media (max-width: $max_xs) {
      padding-bottom: 10px;
    }
  }
  .count {
    margin-top: auto;
    transition: 0.2s;
    opacity: 0;
    transform: translateY(15px);
  }

  .container > .btn-row {
    padding-top: 40px;
    @media (max-width: $max_xs) {
      padding-top: 20px;
    }
  }
  .vac-baner-wrap {
    padding-top: 60px;
    @media (max-width: $max_sm) {
      padding-top: 50px;
    }
  }
}

.create-vacancy-banner {
    @media (max-width: $max_md) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        .vac-banner {
            background-color: #fff;
        }
    }
    @media (max-width: $max_sm) {
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 0;
        max-width: none;
        .vac-banner {
            background-color: #f9f9f9;
        }
    }
    @media (max-width: $max_xs) {
        margin-left: 0;
        margin-right: 0;
    }
}
