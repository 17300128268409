/*==============================================================
*												SECTION VACANCIES LIST
=============================================================== */
.section-company_vacancies_list {
  padding-top: 34px;
  @media (min-width: $min_sm) {
    padding-bottom: 117px;
  }
  .vac-card:last-child {
    margin-bottom: 0;
  }
  @media (max-width: $max_sm) {
    .vac-card {
      margin: 0;
      padding-bottom: 20px;
      padding-top: 25px;
      border-top: 1px solid rgba(140, 153, 176, 0.3);
      border-radius: 0;
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
      padding-left: $sm_col_width;
      padding-right: $sm_col_width;
      &:first-child {
        border-top: 0;
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
