/*==============================================================
*												COLORS
=============================================================== */
$c-black: #383737;
$c-dark: #252525;
$c-blue: #1282f2;
$c-lgrey: #f9f9f9;
$c-dgrey: #9c9b9b;
$c-grey3: #8c99b0;
$c-grey4: #8992a6;
$c-gold: #ffc700;
$c-red: #dd6970;
$c-green: #4baf4f;
$c-orange: #ffac38;
$c-lred: #ed4947;
/*==============================================================
*											  	BREAKPOINTS
=============================================================== */

$min_xs: 576px;
$max_xs: 575.9px;

$min_sm: 768px;
$max_sm: 767.9px;

$min_md: 992px;
$max_md: 991.9px;

$min_lg: 1024px;
$max_lg: 1023.9px;

$min_xl: 1280px;
$max_xl: 1279.9px;

$min_temp: 1440px;
$max_temp: 1439.9px;

$min_xxl: 1660px;
$max_xxl: 1659.9px;

$min_flg: 1980px;
$max_flg: 1979.9px;

/*==============================================================
*												COL SPACES
=============================================================== */
$col_width: 20px;
$sm_col_width: 20px;
