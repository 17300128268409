/*==============================================================
*												header
=============================================================== */
.header {
  border-bottom: 1px solid rgba($c-dark, 0.1);
  .nav-container {
    display: flex;
    align-items: center;
  }

  .hamburger {
    margin-left: 17px;
  }

  .logo-col {
    position: relative;
    z-index: 2;
    a {
      display: block;
    }
  }

  .top-logo img {
    display: block;
  }

  .nav-container {
    @media (max-width: $max_lg) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .controls-col {
    margin-left: auto;
    display: flex;
    align-items: center;
    .btn {
      flex-shrink: 0;
      min-width: 190px;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (min-width: $min_xl) {
      padding-right: 10px;
    }
    @media (max-width: $max_xl) {
      .btn {
        padding-left: 15px;
        padding-right: 15px;
        min-width: 136px;
      }
    }
    @media (max-width: $max_md) {
      margin-right: -10px;
    }
  }
  .top-menu-col {
    display: flex;
    align-items: stretch;
    margin-left: calc(-35px / 2);
    margin-right: calc(-35px / 2);
    padding-left: 40px;
    padding-right: 20px;
    @media (max-width: $max_xl) {
      padding-left: 20px;
    }
  }
  .nav-item {
    padding-left: calc(35px / 2);
    padding-right: calc(35px / 2);
    @media (max-width: $max_xl) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .nav-link {
    display: block;
    padding-top: 36px;
    padding-bottom: 36px;
    position: relative;
    opacity: 0.6;
    transition: opacity 0.2s;
    text-align: center;
    &:hover,
    &.active {
      opacity: 1;
    }
  }
  .login-link {
    display: inline-block;
    &:hover {
      opacity: 1;
    }
    margin-left: 35px;
    @media (max-width: $max_lg) {
      margin-left: 17px;
    }
  }

  .add-btn {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }

  .user-block {
    padding-left: 25px;
    .js-open-user-menu {
      display: flex;
      align-items: center;
      text-align: center;
      &:hover .name,
      &.is-active .name {
        opacity: 0.6;
      }
      &.is-active .rounded-img {
        border-color: $c-blue;
      }
    }
    .name {
      transition: 0.1s;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 180px;
    }
    @media (max-width: $max_xl) {
      padding-left: 15px;
    }
    @media (max-width: $max_lg) {
      padding-left: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .avatar {
      width: 55px;
      height: 55px;
      position: relative;
      flex-shrink: 0;
      border-radius: 50%;
      margin-left: 10px;
      display: block;
      @media (max-width: $max_sm) {
        width: 40px;
        height: 40px;
      }
    }
    .rounded-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      flex-shrink: 0;
      border: 1px solid #bcc8db;
      transition: 0.1s;
    }
    .count {
      position: absolute;
      display: block;
      width: 20px;
      height: 20px;
      background-color: $c-blue;
      box-shadow: 0 0 0 3px #fff;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      transform: translate(25%, -25%);
      @media (max-width: $max_sm) {
        font-size: 12px;
      }
    }
  }

  @media (max-width: $max_xs) {
    .register-link {
      display: none;
    }
    .login-link {
      display: none;
    }
  }
}

.fixed-navbar {
  background-color: #fff;
  z-index: 10000;
  position: relative;
}
.is-fixed {
  .fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgba(140, 153, 176, 0.3);
    .nav-link {
      // padding-top: 23px;
      // padding-bottom: 23px;
      transition: 0.2s;
    }
    .nav-container {
      transition: 0.2s;
    }
  }
  @media (max-width: $max_lg) {
    .nav-container {
      // padding-top: 5px;
      // padding-bottom: 5px;
    }
  }
}

.mobile-menu,
.user-top-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  z-index: 1000;
  transition: 0.2s;
  padding-top: 80px;
  @media (max-width: $max_xs) {
    padding-top: 67px;
  }
  &:not(.active) {
    transform: translateX(-100%);
  }

  .scroll-menu {
    height: 100%;
    overflow: auto;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 99%;
    overflow: hidden;
    & > * {
      width: 100%;
    }
  }

  .menu-container {
    padding: 20px 10px;
  }
  .foot-menu-container {
    margin-top: auto;
  }
  .like-border {
    width: 100vw;
    height: 0;
    border-bottom: 1px solid #e0e1e4;
  }
  .addr-container {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .soc-container {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .menu-link {
    background: transparent;
    border: 0;
    font-size: 15px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    &:hover {
      opacity: 0.6;
    }
  }
  button.menu-link {
    background: transparent;
    border: 0;
    color: #383837;
    font-family: "IBM Plex Sans";
    font-size: 15px;
    font-weight: 500;
    padding: 10px 0;
  }
  .menu-item:not(:last-child) {
    margin-bottom: 5px;
  }
  .account-menu {
    padding-top: 30px;
  }
  .right-arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after {
      content: "";
      display: block;
      height: 13px;
      width: 8px;
      flex-shrink: 0;
      background-position: center center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-image: url("../img/svg/menu-arrow.svg");
    }
  }
  .address {
    font-style: normal;
    line-height: 1.4;
  }
  .contact-row {
    padding-top: 10px;
    display: flex;
    align-items: center;
    .row-title {
      display: inline-block;
      margin-right: 5px;
    }
  }
  .addr-container .title {
    margin-bottom: 15px;
  }
  .nav-socials-list {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    // margin: -5px 0;
    margin-bottom: -10px;
  }
  .soc-col {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    padding-top: 0;
    & > * {
      flex-shrink: 0;
    }
  }

  @media (min-width: $min_xs) {
    .nav-menu,
    .account-menu {
      // max-width: 420px;
    }
  }
}

.user-top-menu {
  &:not(.active) {
    transform: translateX(100%);
  }
  @media (min-width: $min_lg) {
    .menu-container {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
