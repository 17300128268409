/*==============================================================
*												SECTION 404
=============================================================== */
.section-404 {
  padding-top: 70px;
  padding-bottom: 80px;
  .title {
    font-size: 180px;
    margin-bottom: 0;
    margin-bottom: 9px;
    @media (max-width: $max_sm) {
      margin-bottom: 19px;
    }
  }
  .subtitle {
    margin-bottom: 15px;
    @media (max-width: $max_sm) {
      font-size: 18px;
      margin-bottom: 19px;
    }
  }
  .desc {
    margin: 0;
    @media (max-width: $max_xs) {
      font-size: 14px;
    }
  }
  .btn-row {
    padding-top: 30px;
  }
  .btn {
    padding-left: 90px;
    padding-right: 90px;
  }
  .container {
    max-width: 430px;
  }
  @media (max-width: $max_sm) {
    padding-top: 60px;
    padding-bottom: 60px;
    .title {
      font-size: 100px;
    }
    .btn {
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
    }
  }
}
