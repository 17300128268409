@media print {
  .header,
  .header-banner,
  .section-search_small,
  .page-breadcrumbs,
  .bottom-go-back,
  .footer,
  .no-print {
    display: none !important;
  }
}
