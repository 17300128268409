/*==============================================================
*												SECTION ABOUT COMPANY
=============================================================== */
.section-about_company {
  padding-top: 40px;
  padding-bottom: 20px;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: $max_md) {
    padding-bottom: 0;
  }

  @media (min-width: $min_md) {
    .container {
      display: flex;
      align-items: stretch;
      .card-col {
        flex-shrink: 0;
        width: 300px;
        padding-left: 15px;
      }
      .content-col {
        flex: 0 1 100%;
        padding-right: 15px;
      }
    }
  }

  @media (max-width: $max_md) {
    .container {
      display: flex;
      flex-direction: column;
    }
    .card-col {
      order: 1;
    }
    .content-col {
      order: 2;
    }
  }

  .company-domains {
    margin-top: 8px;
  }

  .about-comp-card {
    @media (max-width: $max_md) {
      .card-head {
        margin-bottom: 31px;
      }
      .contacts-block {
        transform: none;
        border: none;
        width: 100%;
        padding: 0;
        margin-bottom: 25px;
      }
      .btn-row {
        margin: 0;
      }
      padding-bottom: 25px;
    }
    @media (max-width: $max_sm) {
      .card-head {
        margin-bottom: 25px;
      }
    }
  }

  .section-title {
    margin: 0;
  }
  .comp-site {
    display: inline-block;
    margin-top: 15px;
  }

  .body-content {
    padding-top: 30px;
  }
  @media (max-width: $max_md) {
    .body-content {
      padding-top: 0;
    }
    .content-col {
      padding-bottom: 25px;
      padding-top: 25px;
      border-top: 1px solid rgba(140, 153, 176, 0.3);
      border-bottom: 1px solid rgba(140, 153, 176, 0.3);
      transform: translateX(-20px);
      width: calc(100% + 40px);
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media (max-width: $max_sm) {
    .content-col {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
      padding-left: $sm_col_width;
      padding-right: $sm_col_width;
    }
  }
  .body-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    .ico {
      position: static;
    }
  }
  .editor-content {
    padding-top: 15px;
  }
  @media (min-width: $min_md) {
    .body-content {
      display: block !important;
    }
  }
  .more-link {
    padding-top: 30px;
    @media (max-width: $max_sm) {
      padding-top: 15px;
    }
  }
}
