/*==============================================================
*												SECTION SINGLE SUMMARY
=============================================================== */
.section-single_summary {
  padding-top: 30px;
  padding-bottom: 40px;

  .info-table .col-value {
    @media (max-width: $max_sm) {
      text-align: right;
    }
  }

  @media screen and (max-width: $max_sm) {
    padding-bottom: 30px;
  }

  @media screen and (min-width: $min_md) {
    .container {
      display: flex;
      align-items: stretch;
    }
    .personal-card-col {
      width: 300px;
      flex-shrink: 0;
      flex-grow: 0;
      padding-right: 15px;
    }
    .summary-col {
      width: 100%;
      padding-left: 15px;
    }
  }

  .collapse-prof-col {
    .action-item .ico {
      position: static;
    }
    @media screen and (min-width: $min_sm) {
      .desc {
        display: block !important;
      }
    }
    @media screen and (max-width: $max_sm) {
      .desc {
        display: none;
      }
      .prof-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .date-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .date {
    color: $c-dgrey;
    margin-bottom: 15px;
  }

  .summary-title {
    margin-bottom: 9px;
  }

  .prof-info {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: -25px;
    margin-top: 0;
    line-height: 1.5;
    @media screen and (max-width: $max_sm) {
      margin: 0;
      padding: 0;
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
    }
  }

  .top-info-block {
    padding-top: 20px;
    padding-bottom: 10px;
    @media screen and (min-width: $min_md) {
      // border-bottom: 1px solid #e0e1e4;
      margin-bottom: 0 !important;
    }
    @media screen and (max-width: $max_sm) {
      padding: 0;
    }
  }

  .prof-col {
    padding: 25px;
    padding-top: 0;

    width: 100%;

    @media screen and (max-width: $max_sm) {
      padding: 15px;
      padding-top: 25px;
      padding-bottom: 18px;
      border-top: 1px solid rgba(140, 153, 176, 0.3);
    }

    &.col-2 {
      @media screen and (min-width: $min_sm) {
        width: 47.5%;
      }
    }

    .desc p {
      margin-bottom: 7px;
      margin-top: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .prof-title {
    margin-bottom: 15px;
    @media screen and (max-width: $max_sm) {
      font-size: 18px;
    }
  }

  .add-info {
    padding-top: 25px;
  }

  @media print {
    .collapse-prof-col .toggle-collapse {
      display: none !important;
    }
    .personal-card {
      padding-bottom: 10px;
    }
    .prof-col.col-2 {
      width: 50%;
    }
    a {
      text-decoration: underline !important;
    }
  }
}
