/*==============================================================
*												SEARCH SECTION
=============================================================== */
.section-search {
  @media (min-width: $min_sm) {
    padding-top: 77px;
    padding-bottom: 76px;
  }
  .page-title {
    text-align: right;
    @media (max-width: $max_sm) {
      display: none;
    }
  }
  .title-wrap {
    display: flex;
  }

  .form-wrap {
    padding-top: 50px;
    @media (max-width: $max_sm) {
      padding-top: 0;
    }
  }

  .tabs-wrap {
    padding-top: 30px;
    @media (max-width: $max_sm) {
      padding-top: 20px;
    }
  }

  .search-line {
    display: flex;
    width: 100%;
  }

  .top-row-wrap {
    display: flex;
    align-items: center;
    @media (max-width: $max_sm) {
      display: flex;
      align-items: stretch;
    }
    .btn {
      min-width: 190px;
      @media (max-width: $max_sm) {
        min-width: auto;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 10px 25px;
        height: 100%;
        .arrow {
          display: none;
        }
      }
    }
    .btn,
    .input,
    .nice-select {
      padding-top: 19px;
      padding-bottom: 19px;
    }
    .btn {
      padding-top: 16.5px;
      padding-bottom: 16.5px;
    }
    .btn-wrap {
      padding-left: 30px;
      @media (max-width: $max_sm) {
        padding-left: 0;
      }
    }
  }
  .bottom-row-wrap {
    padding-top: 20px;
  }
  .fields-group {
    width: 100%;
    .input {
      height: 100%;
    }
    @media (max-width: $max_sm) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
  }
  .input {
    @media (max-width: $max_sm) {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
  .input-wrap {
    &,
    .input {
      width: 100%;
    }
  }
  .nice-select {
    padding-left: 30px;
  }

  @media (max-width: $max_sm) {
    .search-line {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
    }
    .fields-group,
    .btn-wrap {
      height: 45px;
    }
    .input,
    .nice-select,
    .btn {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      height: 100% !important;
    }
    .btn {
      padding-left: 52px !important;
      padding-right: 52px !important;
    }
    .top-row-wrap {
      flex-wrap: wrap;
    }
    .bottom-row-wrap {
      flex: 0 0 100%;
      .place {
        padding-left: 3px;
      }
    }
    .tabs-links-wrap {
      padding-left: 20px;
    }
  }

  @media (max-width: $max_xs) {
    .tabs-links-wrap {
      padding-left: 15px;
    }
    .btn {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}
