/*==============================================================
*												SECTION NEWSPAPER
=============================================================== */
.section-newspaper_banner {
  .title .svg-wrap {
    display: inline-block;
    height: 30px;
    width: 90px;
    transform: translateY(7px);
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .newspaper-banner,
  .subscribe-banner {
    margin-bottom: 40px;
    .content-col {
      padding-left: 65px;
    }
  }
  .newspaper-banner {
    .content-col {
      padding-top: 41px;
      padding-bottom: 41px;
      max-width: 100%;
      width: 394px;
    }
    .title {
      margin-bottom: 14px;
    }
    .btn {
      min-width: 255px;
    }
  }

  .subscribe-banner {
    .content-col {
      padding-top: 70px;
      padding-bottom: 70px;
    }
    .btn {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .input {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .content-col {
      max-width: 675px;
    }
  }

  .text-content {
    @media (min-width: $min_md) {
      padding-top: 10px;
      padding-left: 65px;
      padding-right: 65px;
    }
  }
}

.newspaper-section {
    @media (max-width: $max_sm) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.newspaper-banner {
    @media (max-width: $max_sm) {
        margin-left: -20px;
        margin-right: -20px;
        .over-gradient {
            &:before {
                background: linear-gradient(to right, rgba(48, 41, 41, 0.6), transparent);
            }
        }
        .content-col {
            width: 100%;
            padding: 25px 20px;
        }
        .svg-wrap {
            display: block;
            margin-top: 8px;
        }
        .btn {
            width: 100%;
        }
        .bg-triangle-fff,
        .bg-triangle,
        .img-col {
            display: none;
        }
    }
}

.subscription-section {
    .input-help {
        bottom: -20px;
        padding-left: 20px!important;
    }
    @media (max-width: $max_xs) {
        margin-top: 0;
        margin-left: -20px;
        margin-right: -20px;
        .bg-triangle,
        .img-col {
            display: none;
        }
        .content-col {
            padding: 25px 20px;
        }
    }
}
