/*==============================================================
*												SECTION SIMILAR ARTICLES
=============================================================== */
.section-similar_articles {
  padding-top: 40px;
  padding-bottom: 10px;
  .section-title {
    margin-bottom: 10px;
  }
  @media (max-width: $max_sm) {
    padding-top: 30px;
    .section-title {
      margin-bottom: 0;
    }
  }
  @media (max-width: $max_xs) {
    .container {
      padding: 0;
    }
    .section-title {
      padding-left: $sm_col_width;
      padding-right: $sm_col_width;
    }
    .slick-list {
      padding-left: $sm_col_width;
    }
    .slick-item {
      padding-left: 7.5px;
      padding-right: 7.5px;
    }

    padding-bottom: 0;
  }
}
