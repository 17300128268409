/*==============================================================
*												SECTION CATEGORIES
=============================================================== */
.section-categories-list {
  padding-top: 35px;
  .section-title {
    @media ( max-width: $max_xs ) {
      font-size: 20px;
    }
  }
  @media (min-width: $min_sm) {
    padding-bottom: 98px;
  }
  .section-title {
    margin-bottom: 34px;
  }
  @media (max-width: $max_sm) {
    padding-top: 25px;
    .section-title {
      margin-bottom: 25px;
    }
    .cat-name {
      font-size: 14px;
    }
  }
}
