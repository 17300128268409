/*==============================================================
*												SECTION VACANCIES CATS
=============================================================== */
.section-vacancies-cats {
  padding-top: 40px;
  margin-top: 0;
  + .section-vacancies-cats {
    border-top: 1px solid #e2e2e2;
  }
  .stat-col {
    position: relative;
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
    @media (min-width: $min_sm) {
      .stat-item:before {
        content: "";
        display: block;
        position: absolute;
        width: 65px;
        height: 65px;
        background-color: #b0bac8;
        border-radius: 50%;
        opacity: 0.1;
        z-index: -1;
      }
    }

    &:first-child .stat-item:before {
      left: 70px;
      top: 8px;
    }
    &:nth-child(2n) .stat-item:before {
      top: 9px;
      left: 3px;
    }
    &:nth-child(3n) .stat-item:before {
      left: -39px;
      top: 9px;
    }
  }
  .stat-item {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    padding-right: 85px;
    @media (max-width: $max_md) {
      padding-right: 0;
    }
  }
  .label {
    margin: 0;
  }
  .count {
    margin-bottom: 5px;
    font-size: 30px;
    @media (max-width: $max_md) {
      font-size: 24px;
    }
    @media (max-width: $max_sm) {
      font-size: 20px;
    }
    @media (max-width: $max_xs) {
      font-size: 18px;
    }
  }
  .desc-col {
    padding-left: 25px;
    white-space: nowrap;
    @media (max-width: $max_sm) {
      padding-left: 0;
    }
  }
  .ico-col {
    @media (max-width: $max_sm) {
      display: none;
    }
  }
  .ico-col img {
    max-width: 25px;
    height: auto;
  }
  .stat-row {
    justify-content: center;
    padding-bottom: 20px;

    @media (max-width: $max_sm) {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  @media (max-width: $max_sm) {
    padding-top: 0;
    .stat-row {
      margin-left: -5px;
      margin-right: -5px;
    }
    .stat-col {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .cats-row {
    padding-top: 20px;
    justify-content: center;
    @media (min-width: $min_sm) {
      margin-top: 0;
      margin: -25px calc(-25px / 2);
    }
    @media (max-width: $max_sm) {
      padding-top: 40px;
      margin-bottom: -10px;
    }
  }
  .cat-col {
    @media (max-width: $max_sm) {
      padding-bottom: 10px;
    }
  }
  .cat-card {
    display: block;
    padding: 35px 0px;
    padding-top: 42px;
    height: 100%;
    @media (max-width: $max_md) {
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: $max_sm) {
      max-width: 100%;
      &:hover {
        box-shadow: none;
      }
      background-color: $c-lgrey;
      padding: 0;
      text-align: left;
    }
    .ink {
      background-color: rgba($c-blue, 0.08);
    }
    @media (min-width: $min_sm) {
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    position: relative;
    &:after {
      transition: 0.2s;
      content: "";
      position: absolute;
      border-radius: 3px 3px 0px 0px;
      background-color: $c-blue;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 5px;
      width: calc(100% - 52px);
      opacity: 0;
    }

    .card-content {
      position: relative;
      z-index: 2;
      pointer-events: none;
      height: 100%;
      @media (min-width: $min_sm) {
        display: flex;
        flex-direction: column;
      }
      @media (max-width: $max_sm) {
        padding: 24px 70px;
        padding-right: 40px;
      }
    }
    .desc {
      margin-top: auto;
    }
  }
  .cat-col {
    width: 20%;
    @media (min-width: $min_sm) {
      padding: 25px calc(25px / 2);
      padding-top: 0;
    }

    @media (max-width: $max_md) {
      width: 50%;
    }
    @media (max-width: $max_sm) {
      width: 100%;
    }
    .thumb {
      width: 100%;
      height: 40px;
      display: flex;
      img {
        margin: auto;
        max-height: 100%;
      }
      @media (max-width: $max_sm) {
        height: auto;
        display: block;
        img {
          height: auto;
          width: 30px;
        }
      }
    }
    // .thumb img {
    //   height: 40px;
    //   display: block;
    //   margin-left: auto;
    //   margin-right: auto;
    // }

    .thumb {
      margin-bottom: 40px;
      @media (max-width: $max_sm) {
        margin-bottom: 0;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
      }
    }

    .arrow {
      .svg-fill {
        fill: #acbad2;
      }
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    .title {
      margin-bottom: 10px;
      @media (max-width: $max_sm) {
        margin-bottom: 7px;
      }
    }
  }

  .btn-row {
    padding-top: 40px;
    @media (max-width: $max_sm) {
      padding-top: 30px;
    }
  }
}

.section-site-stats {
    padding-bottom: 50px;
    padding-top: 50px;
    margin-top: 0;
    margin-bottom: 0;
    @media (max-width: $max_sm) {
        padding-bottom: 40px;
        padding-top: 40px;
    }
}
