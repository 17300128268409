/*==============================================================
*												SECTION COMPANIES VACANCIES
=============================================================== */
.section-companies_vacancies {
  padding-top: 35px;
  margin-top: 0;
  @media (min-width: $min_sm) {
    padding-bottom: 78px;
  }
  @media (max-width: $max_sm) {
    padding-top: 25px;
  }
  .section-title {
    margin-bottom: 35px;
    @media (max-width: $max_sm) {
      margin-bottom: 25px;
    }
    @media (max-width: $max_xs) {
      font-size: 20px;
    }
  }
}
.vac-cards-list {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: -30px -15px;
  margin-top: 0;
  .cat-col {
    padding: 30px 15px;
    padding-top: 0;
    width: 25%;
    @media (max-width: $max_md) {
      width: calc(100% / 3);
    }
    @media (max-width: $max_sm) {
      width: 50%;
    }
  }
  .cat-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 35px 15px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    & > * {
      width: 100%;
    }
  }
  .thumb {
    flex-shrink: 0;
    height: 100px;
    display: flex;
    align-items: center;
    img {
      flex-shrink: 0;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .content {
    margin-top: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    .vac-count {
      margin-top: auto;
      padding-top: 12px;
    }
  }

  @media (max-width: $max_xs) {
    margin-bottom: -12px;
    .cat-col {
      width: 100%;
      padding-bottom: 12px;
    }
    .cat-card {
      flex-direction: row;
      align-items: center;
      text-align: left;
      padding: 18px 15px;
      & > * {
        width: auto;
        height: auto;
        margin: 0;
      }
      .thumb {
        width: 30px;
        display: block;
        img {
          width: 100%;
          height: auto;
        }
      }
      .content {
        padding: 0;
        padding-left: 15px;
      }

      .cat-name {
        font-size: 14px;
      }
      .vac-count {
        padding-top: 9px;
      }
    }
  }
}

.cats-anchor-menu {
    margin-bottom: 22px;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-left: -2px;
    margin-right: -2px;
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        height: 40px;
        border-radius: 2px;
        border: 1px solid #e1e1e1;
        font-size: 13px;
        margin: 2px;
        &:hover {
            background-color: #1282f2;
            color: #fff;
        }
        &.selected {
            background-color: #1282f2;
            color: #fff;
            cursor: default;
        }
    }
}
