.fieldset {
  border: none;
  padding: 0;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 30px;
  border-top: 1px solid #e0e1e4;
  @media (max-width: $max_sm) {
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

.form-validate-message {
  padding: 12px 20px;
  border-radius: 3px;
  background-color: $c-lgrey;
  color: $c-dgrey;
  margin-bottom: 20px;
  border: 1px solid transparent;
  a {
      text-decoration: underline;
  }

  &.message_success {
    border-color: #c3e6cb;
    color: #155724;
    background-color: #d4edda;
  }
  &.message_warning {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;

  }
}

.input-desc {
  position: relative;
  .desc {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding-right: 20px;
    padding-left: 20px;
    z-index: 10;
    display: flex;
    align-items: center;
  }
  @media (max-width: $max_xs) {
    .input {
      padding-right: 20px !important;
    }
    .desc {
      position: static;
      padding-right: 0;
      padding-left: 0;
      padding-top: 12px;
      width: 100%;
      display: block;
      text-align: right;
    }
  }
}

.form .legend {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: space-between;
  @media (max-width: $max_sm) {
    display: block;
    .common {
      padding-top: 20px;
    }
  }
  .ico {
    top: 24px;
  }
  &.toggle-collapse {
    padding-right: 25px;
  }
}

.from-to-input {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  .input {
    width: calc(50% - 15px / 2);
  }
}

.input-wrap {
  display: block;
  width: 100%;
  position: relative;
  padding-bottom: 18px;
  padding-top: 5px;
  .input-help {
    padding-left: 32.5px;
  }
  @media (max-width: $max_sm) {
    padding-bottom: 18px;
  }
}

.input-wrap-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    .choice-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 53px;
    }
    @media (max-width: $max_sm) {
      .choice-item {
          height: auto;
      }
    }
}

.checkbox-error-wrap {
  color: $c-red;
  font-size: 12px;
  &:not(.has-error) {
    display: none;
  }

  &.under-label {
    transform: translateY(-10px);
  }
}

.input-has-tooltip {
  display: flex;
  position: relative;
  padding-right: 40px;
  float: left;
  .tooltip-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.label,
.label-helper {
  margin-bottom: 15px;
  @media (max-width: $max_sm) {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.label-helper {
  display: block;
  color: #9c9b9b;
  line-height: 1.5;
}

.label {
  display: block;
  font-size: 14px;
  &.has-helper {
    margin-bottom: 10px;
  }
  &.has-right-help {
    display: flex;
    justify-content: space-between;
  }
  .right-help {
    color: $c-dgrey;
  }
}

.input {
  display: inline-block;
  transition: 0.2s;
  width: 100%;
  background-color: #fff;
  padding: 17px 20px;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  font-size: 13px;
  // @media (max-width: $max_sm) {
  // padding: 17.5px 15px;
  // }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $c-dgrey;
  }
  &:not(.disabled) {
    &:hover {
      background-color: #fbfbfb;
    }
    &:focus {
      border-color: #aabde7;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.has-error {
    &,
    &:focus {
      border-color: $c-red;
    }
    & + .input-help {
      display: block;
      color: $c-red;
    }
  }
}

.input[type="file"] {
    overflow: hidden;
    padding-bottom: 14px;
    padding-top: 14px;
    height: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
  @media (max-width: $max_sm) {
    min-height: 90px;
  }
  &.no-resize {
    resize: none;
  }
}

.input-type-image,
.input-type-doc {
  position: relative;

  display: flex;
  align-items: center;
  padding: 25px;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  @media (max-width: $max_xs) {
    padding: 15px;
  }
  &.has-error {
    & + .input-help {
      display: block;
      color: $c-red;
    }
    border-color: $c-red;
  }

  .preview {
    flex-shrink: 0;
    width: 55px;
    height: 55px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    img {
      flex-shrink: 0;
      flex-grow: 0;
      width: 100%;
      height: auto;
    }
  }
  .uplaod-link {
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    color: $c-blue;
    font-weight: 500;
    transition: 0.2s;
    font-size: 15px;
    &:hover {
      opacity: 0.6;
    }
    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }

  .file-select {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
  }
  .rm-link {
    color: #9c9b9b;
    display: none;
    margin-top: 8px;
  }
}

.input-type-doc.file-uploaded,
.input-type-image.file-uploaded {
    .rm-link {
        display: block;
    }
}

.two-choice-radio {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px;
  border-radius: 3px;
  background-color: #f5f5f5;
  overflow: hidden;
  position: relative;
  label {
    display: block;
    width: 50%;
    cursor: pointer;
    &:first-child {
      padding-right: 3.5px;
    }
    &:last-child {
      padding-left: 3.5px;
    }
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
  }
  .radio-label {
    transition: 0.15s;
    padding: 12px;
    display: block;
    text-align: center;
    border-radius: 4px;
  }
  input:checked ~ .radio-label {
    background-color: #fff;
    font-weight: 500;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
  }
}

.input-help {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 20px;
  transition: 0.2s;
  font-size: 12px;
  @media (max-width: $max_sm) {
    font-size: 11px;
    padding-left: 15px;
  }
}

select.custom-select {
  display: none;
}

.nice-select {
  float: none;
  font-size: 14px;
  &,
  &:hover {
    border: 1px solid #e1e1e1;
  }
  line-height: 1.2;
  height: auto;
  padding: 17px 25px;
  border-radius: 3px;
  padding-right: 72px;
  @media (max-width: $max_sm) {
    font-size: 12px;
    padding: 15px;
    padding-right: 45px;
  }
  &:after {
    right: 25px;
  }
  .list {
    width: 100%;
    max-height: 380px;
    overflow-y: auto;
  }
  .current,
  .option {
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
      width: 100%;
  }
  color: $c-dgrey;

  .current {
    display: block;
    color: $c-black;
    height: 17px;
    overflow: hidden;
    position: relative;
    white-space: normal;
  }
  &.open {
    background-color: #fbfbfb;
    border-color: #aabde7;
  }
  &.disabled {
    opacity: 0.5;
  }
  &.has-error {
    & + .input-help {
      display: block;
      color: $c-red;
    }
    border-color: $c-red;
  }

  &.transparent-select {
    &,
    &:hover {
      border: none;
      background-color: transparent;
    }
    padding-right: 40px;
  }
}

.fields-group {
  display: flex;
  align-items: stretch;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  position: relative;
  .input-help {
    bottom: auto;
    top: calc(100% + 5px);
    // @media (max-width: $max_sm) {
    // top: calc(100% + 5px);
    // }
  }
  &.has-error {
    border-color: $c-red;
    .input-help {
      display: block;
      color: $c-red;
    }
  }
  .input-wrap {
    padding: 0;
  }
  .input {
    border: none;
    background-color: transparent;
    height: 100%;
  }
  .nice-select {
    &,
    &:focus,
    &.open,
    &:hover {
      border-top: none;
      border-bottom: none;
      border-radius: 0;
      border-color: #e1e1e1;
    }
  }
  & > *:first-child {
    border-left: none;
  }
  & > *:last-child {
    border-right: none;
  }

  .input-wrap .input-help {
    padding-left: 20px;
  }
}

.choice-list .choice-item:not(:last-child) {
  margin-bottom: 25px;
}
.choice-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.choice-btn {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  min-height: 20px;
  @media (max-width: $max_xs) {
    min-height: 25px;
  }
  input {
    opacity: 0;
    width: 1px;
    height: 1px;
    position: absolute;
    right: 100%;
    bottom: 100%;
    &:checked ~ .check {
      &:after {
        transform: scale(0.6);
        opacity: 1;
      }
    }
  }

  .check {
    display: block;
    width: 20px;
    height: 20px;
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #e1e1e1;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0);
      opacity: 0;
      transition: 0.1s;
    }
    @media (max-width: $max_xs) {
      width: 25px;
      height: 25px;
    }
  }
  .label {
    margin: 0;
    display: inline-block;
    padding-left: 28px;
    @media (max-width: $max_xs) {
      padding-left: 33px;
    }
  }

  &.radio .check {
    border-radius: 50%;
    &:after {
      border-radius: 50%;
    }
  }
  &.choice-blue {
    &:hover .check,
    input:checked ~ .check {
      border-color: $c-blue;
    }
    .check:after {
      background-color: $c-blue;
    }
  }

  &.checkbox .check {
    border-radius: 4px;
    &:after {
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-image: url("../img/svg/check.svg");
    }
  }
}

.lg-textarea {
  min-height: 170px;
  @media (max-width: $max_sm) {
    min-height: 100px;
  }
}

.form {
  position: relative;
}

.form-col {
  display: flex;
  align-items: stretch;
  margin-left: -12.5px;
  margin-right: -12.5px;
  & > * {
    flex: 1;
    padding-left: 12.5px;
    padding-right: 12.5px;
  }
  &.cols-2 > * {
    width: 50%;
    flex: none;
    flex-grow: 0;
    flex-shrink: 0;
  }

  @media (max-width: $max_sm) {
    flex-wrap: wrap;
    & > *,
    &.cols-2 > * {
      flex: auto;
      width: 100%;
    }
  }
}

.form-row {
  display: flex;
  align-items: center;
  width: 100%;

  .btn[type="submit"] {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .input-wrap {
    padding: 0;
  }

  .btn-wrap {
    padding-left: 15px;
  }
  .input-wrap {
    width: 100%;
  }
}

.form-col.flex-align-center {
    align-items: center;
}

.input-tips {
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    box-sizing: border-box;
    display: none;
    margin-left: -1px;
    margin-top: 2px;
    position: absolute;
    transition: background .2s ease-in-out;
    width: calc(100% + 2px);
    z-index: 100;
    &__item {
        border-bottom: 1px solid #e1e1e1;
        display: block;
        padding: 10px 20px;
        position: relative;
        &-right {
            opacity: .5;
            position: absolute;
            right: 20px;
        }
        &:hover {
            background: #e1e1e1;
        }
        &:last-child {
            border-bottom: none;
        }
    }
}
