/*==============================================================
*												FOOTER
=============================================================== */
.footer {
  .menu-container {
    padding-top: 35px;
    padding-bottom: 35px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @media (max-width: $max_sm) {
      padding-top: 23px;
    }
  }
  .foot-col .title {
    @media (max-width: $max_sm) {
      font-size: 16px;
    }
  }
  .menu-cols-wrap {
    width: 100%;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
  }
  .foot-menu-col {
    padding-left: 25px;
    padding-right: 25px;
  }
  .contacts-col {
    flex-shrink: 0;
    width: 341px;
  }
  .title {
    margin-bottom: 20px;
  }
  .menu-item:not(:last-child) {
    margin-bottom: 10px;
  }
  .menu-link:hover {
    opacity: 0.6;
  }

  .contact-block {
    padding-top: 10px;
    a:hover {
      opacity: 0.6;
    }
  }

  .foot-copyrights {
    border-top: 1px solid rgba(190, 201, 220, 0.5);
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 15px;
      @media (max-width: $max_sm) {
        padding-top: 20px;
      }
    }
  }

  @media (min-width: $min_sm) {
    .collapse-btn:after {
      display: none !important;
    }
    .foot-menu {
      display: block !important;
      height: auto !important;
      opacity: 1 !important;
    }
    .title {
      cursor: auto !important;
    }
  }

  @media (max-width: $max_md) {
    .menu-container {
      display: block;
    }
    .menu-cols-wrap,
    .foot-menu-col {
      padding-left: 0;
      padding-right: 0;
    }
    .foot-col {
      margin-bottom: 38px;
      width: 100%;
    }
    .contacts-col {
      margin-bottom: 0;
    }
    .logo-col {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: $max_xs) {
        .soc-link > * {
          width: 15px;
        }
        .social-item {
          padding: calc(15px / 2);
          padding-top: 0;
        }
        .socials-list {
          margin: calc(-15px / 2);
          margin-top: 0;
          flex-wrap: wrap;
          justify-content: flex-end;
        }
      }
    }
    .socials-list {
      padding: 0;
      padding-left: 10px;
    }
    .title {
      margin-bottom: 15px;
    }
    .menu-cols-wrap {
      margin-left: -15px;
      margin-right: -15px;
      & > * {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .foot-copyrights {
      border-top: 0;
      .container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: flex-start;
      }
      .right-col {
        margin-bottom: 30px;
      }
      .left-col {
        border-top: 1px solid rgba(190, 201, 220, 0.5);
        padding-top: 15px;
        width: 100%;
        text-align: center;
      }
    }

    .menu-container {
      padding-bottom: 0;
    }
  }

  @media (max-width: $max_sm) {
    .foot-copyrights {
      .right-col,
      .left-col {
        padding-left: $sm_col_width;
        padding-right: $sm_col_width;
      }
    }
    .container {
      padding-left: 0;
      padding-right: 0;
      & > * {
        padding-left: $sm_col_width;
        padding-right: $sm_col_width;
      }
    }
    .title {
      margin-bottom: 25px;
    }
    .foot-col {
      margin-bottom: 23px;
      &.contacts-col {
        margin-bottom: 0;
      }
    }
    .menu-cols-wrap {
      display: block;
      margin: 0;
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid rgba(224, 225, 228, 0.5);
      border-bottom: 1px solid rgba(224, 225, 228, 0.5);
      margin-bottom: 30px;
      & > * {
        padding: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .foot-menu {
      display: none;
      overflow: hidden;
      & > *:first-child {
        margin-top: 15px;
      }
      &.active {
        display: block;
      }
    }

    .foot-menu-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .foot-menu-col .title {
      cursor: pointer;
      margin-bottom: 0;
      user-select: none;
      width: 100%;
      // max-width: 150px;
    }
  }

  @media (max-width: $max_xs) {
    .foot-menu-col .title {
      max-width: 100%;
    }
  }
}
