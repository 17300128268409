/*==============================================================
*										SECTION VACANCY ON MAP
=============================================================== */
.section-vac_map {
  padding: 0;
  padding-bottom: 5px;
  & + .bottom-go-back {
    padding-top: 30px;
  }
  .container {
    padding: 0;
  }
  .map-wrap {
    position: relative;
    height: 400px;
  }
  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}
