/*==============================================================
*												SINGLE ARTICLE
=============================================================== */
.section-article {
  padding-top: 30px;
  padding-bottom: 40px;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #e0e1e4;

  .date {
    display: block;
    color: $c-dgrey;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .art-title {
    margin: 0;
    font-size: 36px;
    @media (max-width: $max_md) {
      font-size: 28px;
    }
    @media (max-width: $max_sm) {
      font-size: 24px;
    }
    @media (max-width: $max_xs) {
      font-size: 20px;
    }
  }
  .article-head {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }

  + .bottom-go-back {
    .content {
      border-top: 0;
    }
  }

  @media (max-width: $max_sm) {
    .article-head {
      flex-direction: column-reverse;
      margin-bottom: 25px;
    }
    .date {
      margin-bottom: 0;
      padding-top: 10px;
    }
  }
}
