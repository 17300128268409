/*==============================================================
*												SECTION SINGLE CAT
=============================================================== */
.section-single_cat {
  padding-top: 35px;
  padding-bottom: 35px;
  & + .bottom-go-back {
    @media (min-width: $min_sm) {
      padding-bottom: 23px;
    }
  }
  .section-title {
    margin-bottom: 44px;
  }
  @media (max-width: $max_sm) {
    padding-top: 25px;
    .section-title {
      margin-bottom: 25px;
    }
  }
  .cat-items-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: -30px -15px;
    margin-top: 0;
    font-size: 16px;
  }
  .cat-items-col {
    width: calc(100% / 3);
    flex-shrink: 0;
    flex-grow: 0;
    padding: 30px 15px;
    padding-top: 0;
  }
  .cat-item:not(:last-child) {
    margin-bottom: 16px;
    @media (max-width: $max_sm) {
      margin-bottom: 15px;
    }
  }
  @media (max-width: $max_md) {
    .cat-items-col {
      width: calc(100% / 3);
    }
  }
  @media (max-width: $max_sm) {
    .cat-items-col {
      width: 100%;
      padding-bottom: 10px;
    }
    .cat-items-list {
      margin-bottom: -10px;
    }
    .cat-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .count {
      display: inline-block;
      padding-left: 5px;
    }
  }
}
