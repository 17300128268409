@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-LightItalic.eot");
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"),
    url("../fonts/IBM/IBMPlexSans-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-LightItalic.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-ThinItalic.eot");
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"),
    url("../fonts/IBM/IBMPlexSans-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-ThinItalic.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-Light.eot");
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"),
    url("../fonts/IBM/IBMPlexSans-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-Light.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-Italic.eot");
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"),
    url("../fonts/IBM/IBMPlexSans-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-Italic.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-MediumItalic.eot");
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"),
    url("../fonts/IBM/IBMPlexSans-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-MediumItalic.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-Medium.eot");
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"),
    url("../fonts/IBM/IBMPlexSans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-Medium.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-ExtraLightItalic.eot");
  src: local("IBM Plex Sans ExtraLight Italic"),
    local("IBMPlexSans-ExtraLightItalic"),
    url("../fonts/IBM/IBMPlexSans-ExtraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-ExtraLightItalic.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-SemiBoldItalic.eot");
  src: local("IBM Plex Sans SemiBold Italic"),
    local("IBMPlexSans-SemiBoldItalic"),
    url("../fonts/IBM/IBMPlexSans-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-SemiBoldItalic.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-BoldItalic.eot");
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"),
    url("../fonts/IBM/IBMPlexSans-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-BoldItalic.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-Bold.eot");
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"),
    url("../fonts/IBM/IBMPlexSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-Bold.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-Thin.eot");
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"),
    url("../fonts/IBM/IBMPlexSans-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-Thin.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans.eot");
  src: local("IBM Plex Sans"), local("IBMPlexSans"),
    url("../fonts/IBM/IBMPlexSans.eot?#iefix") format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-ExtraLight.eot");
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"),
    url("../fonts/IBM/IBMPlexSans-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-ExtraLight.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBM/IBMPlexSans-SemiBold.eot");
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"),
    url("../fonts/IBM/IBMPlexSans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/IBM/IBMPlexSans-SemiBold.woff") format("woff"),
    url("../fonts/IBM/IBMPlexSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
