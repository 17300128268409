/*==============================================================
*												SECTION ARTICLES / NEWS
=============================================================== */
.section-articles_news {
  padding-top: 40px;
  margin-top: 0;
  .section-title {
    margin-bottom: 35px;
  }
  .tabs-links-wrap {
    margin-bottom: 35px;
  }
  .tab-link.active {
    font-weight: 500;
  }
  .tab-link {
    font-size: 14px;
  }

  @media (max-width: $max_xs) {
    .tabs-links-wrap {
      justify-content: center;
      border-bottom: 1px solid rgba(140, 153, 176, 0.3);
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
      padding-left: $sm_col_width;
      padding-right: $sm_col_width;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 25px;
    }
    .tab-link-item {
      padding-left: 0;
      padding-right: 0;
    }
    .tab-link {
      padding-left: 15px;
      padding-right: 15px;
      min-width: 140px;
      text-align: center;
    }
    .section-title {
      font-size: 20px;
      margin-bottom: 30px;
    }
    .tab-link {
      font-size: 16px;
    }
  }
  @media (max-width: 319.9px) {
    .tab-link {
      min-width: auto;
    }
  }
}
