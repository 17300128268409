/*==============================================================
*												SECTION REGISTER BANNER
=============================================================== */
.section-register_banner {
  padding-bottom: 0;
  margin-bottom: 60px;
  @media (max-width: $max_sm) {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
  .banner-wrap {
    padding-top: 65px;
    @media (max-width: $max_md) {
      padding-top: 50px;
    }
    @media (max-width: $max_sm) {
      padding-top: 40px;
    }
    @media (max-width: $max_xs) {
      padding-top: 30px;
    }
  }
  .container {
    @media (max-width: $max_sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .register-banner .desc {
    @media (max-width: $max_sm) {
      font-size: 14px;
    }
  }
}
