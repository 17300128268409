/*==============================================================
*												SECTION SEARCH SMALL
=============================================================== */
.section-search_small {
  padding-top: 35px;
  padding-bottom: 35px;

  .tab-select .current {
    font-weight: 500;
    font-size: 18px;
  }

  .tab-select {
    @media (min-width: $min_sm) {
      margin-left: -22px;
    }
  }

  .input {
    @media (max-width: $max_xs) {
      padding-left: 15px;
    }
  }

  .tab {
    .input,
    .nice-select,
    .btn {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 100% !important;
    }
    .fields-group,
    .btn-wrap {
      height: 55px;
      @media (max-width: $max_sm) {
        height: 45px;
      }
    }
    .nice-select .current {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .btn {
      @media (min-width: $min_sm) {
        min-width: 160px;
      }
    }
  }

  @media (max-width: $max_sm) {
    padding-top: 30px;
    padding-bottom: 34px;
  }
  border-bottom: 1px solid rgba(37, 37, 37, 0.1);

  .select-tabs-wrap,
  .fields-group {
    width: 100%;
  }

  @media (min-width: $min_sm) {
    .container,
    .form {
      display: flex;
      align-items: center;
    }
    .btn-wrap {
      padding-left: 30px;
    }
    .select-tabs-wrap {
      padding-left: 30px;
    }
  }
  @media (max-width: $max_sm) {
    .form {
      display: flex;
      .input {
        height: 100%;
      }
    }
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      @media (max-width: $max_xs) {
        font-size: 13px;
      }
    }
    .fields-group {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .bottom-row-wrap {
      padding-top: 20px;
      .place {
        display: inline-block;
        margin-left: 3px;
      }
    }
    .tabs-links-wrap {
      margin-bottom: 20px;
      padding-left: 20px;
      margin-left: calc(-25px / 2);
      margin-right: calc(-25px / 2);
      @media (max-width: $max_xs) {
        padding-left: 15px;
      }
    }
    .tab-link-item {
      padding-left: calc(25px / 2);
      padding-right: calc(25px / 2);
    }
  }
}
