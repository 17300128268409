/*==============================================================
*												SECTION NEWS
=============================================================== */
.section-news {
  .section-title {
    margin-bottom: 45px;
  }
  .btn-row {
    padding-top: 45px;
  }
  + .section-vacancies_hot {
      padding-top: 0;
  }
}
