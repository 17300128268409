/*==============================================================
*												SECTION REGISTER
=============================================================== */
.section-register {
  padding-top: 50px;
  padding-bottom: 80px;
  margin-bottom: 0;
  margin-top: 0;
  @media (max-width: $max_sm) {
    padding-top: 30px;
  }
  .tabs-links-wrap {
    justify-content: center;
    .bottom-arrow {
      height: 5px;
    }
  }
  .tab-link {
    padding-left: 40px;
    padding-right: 40px;
    min-width: 255px;
    text-align: center;
    padding-bottom: 23px;
    &.active {
      font-weight: 500;
    }
    @media (max-width: $max_sm) {
      min-width: auto;
      font-size: 16px;
      padding-bottom: 19px;
    }
  }
  .tabs-wrap {
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    padding: 50px 25px;
  }
  .tab {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }

  .socials-register {
    .title {
      margin-bottom: 30px;
    }
    margin-bottom: 35px;
  }

  .socials-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -44px -22px;
    margin-top: 0;
    margin-bottom: -22px;
  }
  .soc-col {
    padding: 44px 22px;
    padding-bottom: 22px;
    padding-top: 0;
  }
  .hr-text {
    margin-bottom: 29px;
  }
  .form-rule {
    padding-top: 20px;
    @media (max-width: $max_xs) {
      font-size: 14px;
    }
  }
  .btn-row {
    padding-top: 20px;
  }
  .btn {
    width: 100%;
  }

  .register-step-3,
  .register-step-2 {
    .title {
      margin-bottom: 15px;
      @media (max-width: $max_sm) {
        font-size: 18px;
      }
    }
    .desc {
      line-height: 1.35;
      @media ( max-width: $max_xs ) {
        font-size: 14px;
      }
    }
    .form {
      padding-top: 40px;
    }
  }

  .socials-list {
    padding-top: 0;
    flex-wrap: wrap;
  }

  @media (max-width: $max_sm) {
    padding-bottom: 40px;
    .tabs-wrap {
      border-left: none;
      border-right: none;
      border-bottom: none;
      border-radius: 0;

      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);

      padding: 30px 15px;
      padding-bottom: 0;
    }
    .tab {
      max-width: 100%;
    }
    .hr-text {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
      margin-bottom: 30px;
    }
    .socials-register {
      margin-bottom: 30px;
      .title {
        margin-bottom: 20px;
        @media (max-width: $max_sm) {
          font-size: 16px;
        }
      }
    }
    .tab-link {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: $max_xs) {
    .tab-link-item {
      padding: 0;
    }
    .tabs-links-wrap {
      margin-left: 0;
      margin-right: 0;
    }
    .tab-link {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
