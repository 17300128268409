/*==============================================================
*												SECTION CONTACTS
=============================================================== */
.section-contacts {
  padding-top: 40px;
  padding-bottom: 60px;
  margin-bottom: 0;
  margin-top: 0;

  @media (max-width: $max_sm) {
    .social-item svg {
      width: 45px !important;
      height: 45px !important;
    }
    .socials-list {
      justify-content: space-between !important;
    }
  }
  .section-title {
    @media (max-width: $max_xs) {
      font-size: 20px;
    }
  }
  .container {
    display: flex;
    align-items: stretch;
  }
  .section-title {
    margin-bottom: 40px;
  }
  .contact-block {
    margin-bottom: 25px;
  }
  .contact-label {
    color: $c-dgrey;
    margin-bottom: 10px;
    font-size: 13px;
  }
  address {
    font-style: normal;
  }
  .socials-list {
    padding-top: 15px;
  }
  .contacts-col {
    padding-bottom: 60px;
    width: 40%;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
  }
  .map-col {
    width: 100%;
    position: relative;
  }
  iframe {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-width: $max_md) {
    padding-bottom: 0;
    .container {
      display: block;
    }
    .contacts-col {
      width: 100%;
      padding-bottom: 40px;
    }
    .map-col {
      height: 350px;
      transform: translateX(-20px);
      width: calc(100% + 40px);
    }
  }

  @media (max-width: $max_sm) {
    .section-title {
      margin-bottom: 25px;
    }
    .contacts-col {
      padding-bottom: 20px;
    }
    .map-col {
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
    }
    .contact-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      & > * {
        width: 50%;
        flex-shrink: 0;
        margin: 0;
        &:not(.contact-label) {
          text-align: right;
        }
      }
    }
    .contacts-col > * {
      order: 1;
      &.contact-address {
        order: 2;
      }
    }
    .socials-list {
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 30px;
      padding-top: 20px;
    }
  }
}
