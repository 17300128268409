/*==============================================================
*												SECTION SINGLE VACANCY
=============================================================== */
.section-single_vacancy {
  padding-top: 30px;
  padding-bottom: 40px;
  @media (max-width: $max_sm) {
    padding-bottom: 35px;
  }
  .info-block {
    line-height: 1.5;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
      @media (max-width: $max_sm) {
        margin-bottom: 5px;
      }
    }

    .info-title {
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ico {
        position: static;
      }
      @media (max-width: $max_sm) {
        margin-bottom: 10px;
      }
    }

    &.collapse-info-block .info-body {
      @media (min-width: $min_sm) {
        display: block !important;
      }
      @media (max-width: $max_sm) {
        display: none;
      }
    }

    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      li:not(:last-child) {
        margin-bottom: 7px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .vac-info .info-block {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: $max_sm) {
    .info-block,
    .vac-info .info-block,
    .vac-info .info-block:last-child {
      margin-bottom: 0;
      padding-bottom: 20px;
      padding-top: 20px;
      border-top: 1px solid #e0e1e4;
      transform: translateX(-$sm_col_width);
      width: calc(100% + #{$sm_col_width} * 2);
      padding-left: $sm_col_width;
      padding-right: $sm_col_width;
    }
  }

  @media (min-width: $min_sm) {
    .vac-info {
      padding-top: 35px;
      margin-top: 35px;
      border-top: 1px solid #e0e1e4;
    }
  }

  .date {
    display: block;
    color: $c-dgrey;
    margin-bottom: 15px;
  }
  .vac-title {
    margin-bottom: 15px;
  }

  .salary {
    margin-bottom: 25px;
  }
  .info-table {
    @media (min-width: $min_sm) {
      margin-bottom: 25px;
    }
  }
  .container {
    display: flex;
    align-items: stretch;
  }

  @media (min-width: $min_md) {
    .controls-col {
      width: 300px;
      flex-shrink: 0;
      padding-left: 15px;
    }

    .vacancy-col {
      padding-right: 15px;
      width: 100%;
    }
  }

  @media (max-width: $max_md) {
    .container {
      flex-direction: column;
    }
    .controls-col {
      order: 1;
    }
    .vacancy-col {
      order: 2;
      padding-top: 25px;
    }
  }

  @media (max-width: $max_sm) {
    .vacancy-col {
      margin-bottom: -20px;
    }
  }

  .controls-col > * {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: $max_md) {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .vac-title {
    margin-bottom: 11px;
  }
}
