/*==============================================================
*												SEARCH / FILTER
=============================================================== */
.search-results {
  padding-top: 30px;
  padding-bottom: 60px;

  .search-title {
    margin: 0;
  }

  &.filter-is-visible {
    .js-open-filter .svg-fill {
      fill: $c-blue;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .action-item {
      user-select: none;
      margin-left: 10px;
    }
  }

  .res-container {
    display: flex;
    align-items: stretch;
    padding-top: 40px;
    position: relative;
    @media (max-width: $max_sm) {
      padding-top: 0;
    }
  }

  .filter-col {
    background-color: #fff;
    @media (min-width: $min_md) {
      flex-shrink: 0;
      width: 270px;
      padding-right: 15px;
    }
    @media (max-width: $max_sm) {
      padding-top: 30px;
    }
  }

  .results-col {
    width: 100%;
    @media (min-width: $min_md) {
      padding-left: 15px;
    }
  }

  @media (min-width: $min_md) {
    .filter-col,
    .results-col {
      display: block !important;
    }
  }

  @media (max-width: $max_md) {
    .filter-col,
    .results-col {
      width: 100%;
      flex-shrink: 0;
      // transition: 0.2s;
      padding-left: 20px;
      padding-right: 20px;
    }
    .res-container {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      overflow: hidden;
    }
    .filter-col {
      display: none;
    }
  }

  @media (max-width: $max_sm) {
    .filter-col,
    .results-col {
      padding-left: $sm_col_width;
      padding-right: $sm_col_width;
    }
  }

  .search-options:not(.has-options) {
    display: none;
  }

  .search-options {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (min-width: $min_sm) {
      padding-bottom: 30px;
    }
    @media (max-width: $max_sm) {
      padding-top: 15px;
    }
  }

  .clear-search-options {
    display: inline-block;
    margin-top: 15px;
    color: $c-dgrey;
    &:hover {
      opacity: 0.6;
    }
    @media (max-width: $max_md) {
      display: none;
    }
  }

  .ppi-wrap {
    display: flex;
    margin: -6px;
    flex-wrap: wrap;
    @media (min-width: $min_md) {
      padding-right: 15px;
    }
    @media (max-width: $max_sm) {
      margin: -5px;
    }
  }
  .ppi {
    background-color: #f9f9f9;
    margin: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    & > * {
      display: inline-block;
      padding: 13px 15px;
    }
    @media (max-width: $max_sm) {
      & > * {
        padding: 12px;
      }
      margin: 5px;
    }
  }

  .ppi-rm {
    border-left: 1px solid rgba(204, 204, 204, 0.2);
  }
}

.search-filter {
  .input-wrap {
    padding: 0;
  }
  .filter-block {
    margin-bottom: 35px;
    @media (max-width: $max_sm) {
      margin-bottom: 25px;
    }
  }
  .apply-filter {
    color: $c-black !important;
    font-weight: 600 !important;
    width: 100%;
    font-size: 15px;
    &.blue-btn {
      color: #fff !important;
    }
  }

  .cats-block {
    .heading {
      display: flex;
      margin-bottom: 25px;
    }
    .cat-title {
      display: flex;
      align-items: center;
      font-weight: 500;

      transition: 0.15s;

      .title {
        display: inline-block;
      }
      .ico {
        margin-right: 10px;
      }
    }

    a.cat-title {
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }

    .cat-item:not(.active) {
      display: none;
    }

    .cat-item {
      a.disabled {
          cursor: default;
      }
      a:not(.disabled):hover {
        opacity: 0.6;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    .show-more-item {
      padding-top: 5px;
      a {
        font-size: 15px;
        font-weight: 500;
        color: $c-blue;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .input-wrap > .label {
    margin-bottom: 20px;
  }

  .choice-item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.search-results-list > * {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
.search-results-list {
  @media (max-width: $max_sm) {
    padding-top: 10px;
  }
}

.search-summary-card {
  display: flex;
  @media (min-width: $min_sm) {
    padding: 30px;
    border-radius: 3px;
    border: 1px solid #e1e1e1;
  }
  @media (max-width: $max_sm) {
    padding: 20px 15px;
    border-bottom: 1px solid #e1e1e1;
    transform: translateX(-$sm_col_width);
    width: calc(100% + #{$sm_col_width} * 2);
    &:first-child {
      padding-top: 0;
    }
  }
  background-color: #fff;
  .avatar {
    flex-shrink: 0;
    border-radius: 50%;
    position: relative;
    width: 55px;
    height: 55px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content-col {
    padding-left: 20px;
    width: 100%;
    @media (max-width: $max_sm) {
      padding-left: 15px;
    }
  }
  .salary,
  .meta-row {
    padding-top: 18px;
    @media (max-width: $max_xs) {
      padding-top: 15px;
    }
  }
  .meta-row {
    display: flex;
    @media (max-width: $max_xs) {
      flex-wrap: wrap;
      .date {
        width: 100%;
        display: block;
        padding-top: 7px;
      }
    }
  }
  .date {
    margin-left: auto;
  }
  .meta-div {
    display: inline-block;
    opacity: 0.2;
    padding: 0 10px;
  }
}

.search-res-vac-card {
  .date {
    padding: 0;
    margin-left: auto;
    color: $c-dgrey;
  }
  .status-wrap {
    display: flex;
    align-items: center;
    @media (max-width: $max_xs) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 5px;
      & > *:not(.date) {
        order: 2;
        width: 100%;
      }
      .date {
        order: 1;
        margin: 0;
        margin-bottom: 15px;
      }
    }
  }
  @media (max-width: $max_sm) {
    transform: translateX(-$sm_col_width);
    width: calc(100% + #{$sm_col_width} * 2);
    padding: 20px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(140, 153, 176, 0.3);
      margin-bottom: 0;
    }
  }
  @media (max-width: $max_xs) {
    .tag-btn {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}
